import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import { sendSummary } from '../../../api/requests/oos'
import { format } from 'date-fns'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Flex,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Box,
} from '@chakra-ui/react'

function ModalSummary({
    selectedFlight,
    selectedDepartureFlight,
    selectedReturnFlight,
    selectedFlightBaggage,
    selectedDepartureFlightBaggage,
    selectedReturnFlightBaggage,
    selectedMeals,
    selectedDepartureMeals,
    selectedReturnMeeals,
    selectedHotel,
    trip,
    tickets,
    isOpen,
    onClose,
    onSuccess,
}) {
    console.log('selectedFlight', selectedFlight)
    console.log('selectedDepartureFlight', selectedDepartureFlight)
    console.log('selectedReturnFlight', selectedReturnFlight)
    console.log('selectedFlightBaggage', selectedFlightBaggage)
    console.log(
        'selectedDepartureFlightBaggage',
        selectedDepartureFlightBaggage
    )
    console.log('selectedReturnFlightBaggage', selectedReturnFlightBaggage)
    console.log('selectedHotel', selectedHotel)
    console.log('trip', trip)
    console.log('tickets', tickets)
    console.log('isOpen', isOpen)
    console.log('onClose', onClose)
    console.log('onSuccess', onSuccess)
    const [searchParams] = useSearchParams()

    function getRoundTripDeparturePriceFlight() {
        return (
            Number(
                selectedDepartureFlight?.journeys[0]?.fareInfo?.netToAgent
                    ?.adultFare?.amount
            ) * trip?.passenger?.length
        )
    }

    function getRoundTripDeparturePriceBaggage() {
        return (
            Number(
                selectedDepartureFlightBaggage.reduce(
                    (accumulator, current) =>
                        accumulator + parseFloat(current.netToAgent?.amount),
                    0
                )
            ) * trip?.passenger?.length
        )
    }

    function getRoundTripDeparturePriceMeals() {
        return (
            Number(
                selectedDepartureMeals.reduce(
                    (accumulator, current) =>
                        accumulator + parseFloat(current.netToAgent?.amount),
                    0
                )
            ) * trip?.passenger?.length
        )
    }

    function getRoundTripDeparturePriceTotal() {
        return (
            getRoundTripDeparturePriceFlight() +
            getRoundTripDeparturePriceBaggage() +
            getRoundTripDeparturePriceMeals()
        )
    }

    function getRoundTripReturnPriceFlight() {
        return (
            Number(
                selectedReturnFlight?.journeys[0]?.fareInfo?.netToAgent
                    ?.adultFare?.amount
            ) * trip?.passenger?.length
        )
    }

    function getRoundTripReturnPriceBaggage() {
        return (
            Number(
                selectedReturnFlightBaggage.reduce(
                    (accumulator, current) =>
                        accumulator + parseFloat(current.netToAgent?.amount),
                    0
                )
            ) * trip?.passenger?.length
        )
    }

    function getRoundTripReturnPriceMeals() {
        return (
            Number(
                selectedReturnMeeals.reduce(
                    (accumulator, current) =>
                        accumulator + parseFloat(current.netToAgent?.amount),
                    0
                )
            ) * trip?.passenger?.length
        )
    }

    function getRoundTripReturnPriceTotal() {
        return (
            getRoundTripReturnPriceFlight() +
            getRoundTripReturnPriceBaggage() +
            getRoundTripReturnPriceMeals()
        )
    }

    function getSmartComboDepartureFlightPrice() {
        return selectedDepartureFlight?.fare * trip?.passenger?.length
    }

    function getSmartComboDepartureBaggagePrice() {
        return (
            selectedDepartureFlightBaggage.reduce(
                (accumulator, current) =>
                    accumulator + parseFloat(current.netToAgent?.amount),
                0
            ) * trip?.passenger?.length
        )
    }

    function getSmartComboDepartureMealsPrice() {
        return (
            selectedDepartureMeals.reduce(
                (accumulator, current) =>
                    accumulator + parseFloat(current.netToAgent?.amount),
                0
            ) * trip?.passenger?.length
        )
    }

    function getSmartComboDepartureTotalPrice() {
        return (
            getSmartComboDepartureFlightPrice() +
            getSmartComboDepartureBaggagePrice() +
            getSmartComboDepartureMealsPrice()
        )
    }

    function getSmartComboReturnFlightPrice() {
        return selectedReturnFlight?.fare * trip?.passenger?.length
    }

    function getSmartComboReturnBaggagePrice() {
        return (
            selectedReturnFlightBaggage.reduce(
                (accumulator, current) =>
                    accumulator + parseFloat(current.netToAgent?.amount),
                0
            ) * trip?.passenger?.length
        )
    }

    function getSmartComboReturnMealsPrice() {
        return (
            selectedReturnMeeals.reduce(
                (accumulator, current) =>
                    accumulator + parseFloat(current.netToAgent?.amount),
                0
            ) * trip?.passenger?.length
        )
    }

    function getSmartComboReturnTotalPrice() {
        return (
            getSmartComboReturnFlightPrice() +
            getSmartComboReturnBaggagePrice() +
            getSmartComboReturnMealsPrice()
        )
    }

    function handleCalculateTotalPrice() {
        let roundTripPrice = 0
        let notRountripPrice = 0
        let hotelPrice = 0
        let smartComboPrice = 0

        if (tickets?.bookingFlightType === 'one_way') {
            console.log({
                selectedFlight,
                selectedFlightBaggage,
                selectedMeals,
            })
            notRountripPrice =
                Number(
                    selectedFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare
                        ?.amount
                ) *
                    trip?.passenger?.length +
                Number(
                    selectedFlightBaggage.reduce(
                        (accumulator, current) =>
                            accumulator +
                            parseFloat(current.netToAgent?.amount),
                        0
                    )
                ) *
                    trip?.passenger?.length +
                Number(
                    selectedMeals.reduce(
                        (accumulator, current) =>
                            accumulator +
                            parseFloat(current.netToAgent?.amount),
                        0
                    )
                ) *
                    trip?.passenger?.length
        }
        if (tickets?.bookingFlightType === 'round_trip') {
            roundTripPrice =
                Number(
                    selectedDepartureFlight?.journeys[0]?.fareInfo?.netToAgent
                        ?.adultFare?.amount
                ) *
                    trip?.passenger?.length +
                Number(
                    selectedDepartureFlightBaggage.reduce(
                        (accumulator, current) =>
                            accumulator +
                            parseFloat(current.netToAgent?.amount),
                        0
                    )
                ) *
                    trip?.passenger?.length +
                Number(
                    selectedReturnFlight?.journeys[0]?.fareInfo?.netToAgent
                        ?.adultFare?.amount
                ) *
                    trip?.passenger?.length +
                Number(
                    selectedReturnFlightBaggage.reduce(
                        (accumulator, current) =>
                            accumulator +
                            parseFloat(current.netToAgent?.amount),
                        0
                    )
                ) *
                    trip?.passenger?.length +
                Number(
                    selectedDepartureMeals.reduce(
                        (accumulator, current) =>
                            accumulator +
                            parseFloat(current.netToAgent?.amount),
                        0
                    )
                ) *
                    trip?.passenger?.length +
                Number(
                    selectedReturnMeeals.reduce(
                        (accumulator, current) =>
                            accumulator +
                            parseFloat(current.netToAgent?.amount),
                        0
                    )
                ) *
                    trip?.passenger?.length
        }
        if (tickets?.bookingFlightType === 'smart_combo') {
            smartComboPrice =
                selectedDepartureFlight?.fare * trip?.passenger?.length +
                selectedReturnFlight?.fare * trip?.passenger?.length +
                selectedDepartureFlightBaggage.reduce(
                    (accumulator, current) =>
                        accumulator + parseFloat(current.netToAgent?.amount),
                    0
                ) *
                    trip?.passenger?.length +
                selectedReturnFlightBaggage.reduce(
                    (accumulator, current) =>
                        accumulator + parseFloat(current.netToAgent?.amount),
                    0
                ) *
                    trip?.passenger?.length +
                selectedDepartureMeals.reduce(
                    (accumulator, current) =>
                        accumulator + parseFloat(current.netToAgent?.amount),
                    0
                ) *
                    trip?.passenger?.length +
                selectedReturnMeeals.reduce(
                    (accumulator, current) =>
                        accumulator + parseFloat(current.netToAgent?.amount),
                    0
                ) *
                    trip?.passenger?.length
        }
        if (tickets?.isBookHotel) {
            hotelPrice =
                Number(selectedHotel?.room?.rates?.chargeableRate?.total) *
                trip?.jumlah_kamar *
                trip?.hotel_durasi
        }

        console.log({
            roundTripPrice,
            notRountripPrice,
            hotelPrice,
        })

        return roundTripPrice + notRountripPrice + hotelPrice + smartComboPrice
    }

    async function handleSubmit() {
        let roundTripData = {
            departure: [],
            return: [],
        }
        let notRoundTripData = []
        let smartComboData = {
            departure: [],
            return: [],
        }
        let hotelData = []

        if (tickets?.bookingFlightType === 'one_way') {
            trip?.passenger?.map((passenger) => {
                let singleNotRoundTripData = {}

                singleNotRoundTripData.pessenger_name =
                    passenger?.passenger_name?.name
                singleNotRoundTripData.rc_code = '-'
                singleNotRoundTripData.title = `${
                    selectedFlight?.departureAirport
                } - ${selectedFlight?.arrivalAirport} (${
                    selectedFlight?.numOfTransits === '0' ? 'direct' : 'transit'
                })`
                singleNotRoundTripData.details =
                    selectedFlight?.airlineDetail[0]?.airlineName
                singleNotRoundTripData.date_time = `${selectedFlight?.journeys[0]?.departureDetail?.departureDate} (${selectedFlight?.journeys[0]?.departureDetail?.departureTime} - ${selectedFlight?.journeys[0]?.arrivalDetail?.arrivalTime})`
                singleNotRoundTripData.status = 'On Booking'
                singleNotRoundTripData.info = 'On Booking'
                singleNotRoundTripData.additional_info =
                    selectedFlight?.journeys[0]?.refundableStatus ===
                    'REFUNDABLE'
                        ? 'Refundable'
                        : 'Non-Refundable'
                singleNotRoundTripData.price =
                    Number(
                        selectedFlight?.journeys[0]?.fareInfo?.netToAgent
                            ?.adultFare?.amount
                    ) * trip?.passenger?.length
                singleNotRoundTripData.subtotal =
                    Number(
                        selectedFlight?.journeys[0]?.fareInfo?.netToAgent
                            ?.adultFare?.amount
                    ) *
                        trip?.passenger?.length +
                    Number(
                        selectedFlightBaggage.reduce(
                            (accumulator, current) =>
                                accumulator +
                                parseFloat(current.netToAgent?.amount),
                            0
                        )
                    ) *
                        trip?.passenger?.length +
                    Number(
                        selectedMeals.reduce(
                            (accumulator, current) =>
                                accumulator +
                                parseFloat(current.netToAgent?.amount),
                            0
                        )
                    ) *
                        trip?.passenger?.length

                singleNotRoundTripData.addOns = []

                // Appending selected baggage to summary addOns
                selectedFlightBaggage.forEach((data) => {
                    singleNotRoundTripData.addOns.push({
                        type: data?.type,
                        sourceAirport: data?.sourceAirport
                            ? data?.sourceAirport
                            : '',
                        destinationAirport: data?.destinationAirport
                            ? data?.destinationAirport
                            : '',
                        marketing_airline: data?.marketing_airline
                            ? data?.marketing_airline
                            : '',
                        operating_airline: data?.operating_airline
                            ? data?.operating_airline
                            : '',
                        baggage_type: data?.baggageType,
                        baggage_quantity: data?.baggageQuantity,
                        bagagge_weight: data?.baggageWeight,
                        baggage_price: data?.netToAgent?.amount,
                        baggage_currency: data?.netToAgent?.currency,
                        meal_quantity: '',
                        meal_display_name: '',
                        meal_price: '',
                        meal_currency: '',
                        baggage_add_ons_id: data?.id,
                        meal_add_ons_id: '',
                    })
                })

                // Appending selected meals to summary addOns
                selectedMeals.forEach((data) => {
                    singleNotRoundTripData.addOns.push({
                        type: data?.type,
                        sourceAirport: data?.sourceAirport
                            ? data?.sourceAirport
                            : '',
                        destinationAirport: data?.destinationAirport
                            ? data?.destinationAirport
                            : '',
                        marketing_airline: data?.marketing_airline
                            ? data?.marketing_airline
                            : '',
                        operating_airline: data?.operating_airline
                            ? data?.operating_airline
                            : '',
                        baggage_type: '',
                        baggage_quantity: '',
                        bagagge_weight: '',
                        baggage_price: '',
                        baggage_currency: '',
                        baggage_add_ons_id: '',
                        meal_quantity: data?.quantity,
                        meal_display_name: data?.displayName,
                        meal_price: data?.priceWithCurrency?.amount,
                        meal_currency: data?.priceWithCurrency?.currency,
                        meal_add_ons_id: data?.id,
                    })
                })

                notRoundTripData.push(singleNotRoundTripData)
            })
        }
        if (tickets?.bookingFlightType === 'round_trip') {
            trip?.passenger?.map((passenger) => {
                let singleDepartureData = {}
                let singleReturnData = {}

                singleDepartureData.pessenger_name =
                    passenger?.passenger_name?.name
                singleDepartureData.rc_code = '-'
                singleDepartureData.title = `${
                    selectedDepartureFlight?.departureAirport
                } - ${selectedDepartureFlight?.arrivalAirport} (${
                    selectedDepartureFlight?.numOfTransits === '0'
                        ? 'direct'
                        : 'transit'
                })`
                singleDepartureData.details =
                    selectedDepartureFlight?.airlineDetail[0]?.airlineName
                singleDepartureData.date_time = `${selectedDepartureFlight?.journeys[0]?.departureDetail?.departureDate} (${selectedDepartureFlight?.journeys[0]?.departureDetail?.departureTime} - ${selectedDepartureFlight?.journeys[0]?.arrivalDetail?.arrivalTime})`
                singleDepartureData.status = 'On Booking'
                singleDepartureData.info = 'On Booking'
                singleDepartureData.additional_info =
                    selectedDepartureFlight?.journeys[0]?.refundableStatus ===
                    'REFUNDABLE'
                        ? 'Refundable'
                        : 'Non-Refundable'
                singleDepartureData.price = Number(
                    selectedDepartureFlight?.journeys[0]?.fareInfo?.netToAgent
                        ?.adultFare?.amount
                )
                singleDepartureData.subtotal = getRoundTripDeparturePriceTotal()

                singleReturnData.pessenger_name =
                    passenger?.passenger_name?.name
                singleReturnData.rc_code = '-'
                singleReturnData.title = `${
                    selectedReturnFlight?.departureAirport
                } - ${selectedReturnFlight?.arrivalAirport} (${
                    selectedReturnFlight?.numOfTransits === '0'
                        ? 'direct'
                        : 'transit'
                })`
                singleReturnData.details =
                    selectedReturnFlight?.airlineDetail[0]?.airlineName
                singleReturnData.date_time = `${selectedReturnFlight?.journeys[0]?.departureDetail?.departureDate} (${selectedReturnFlight?.journeys[0]?.departureDetail?.departureTime} - ${selectedReturnFlight?.journeys[0]?.arrivalDetail?.arrivalTime})`
                singleReturnData.status = 'On Booking'
                singleReturnData.info = 'On Booking'
                singleReturnData.additional_info =
                    selectedReturnFlight?.journeys[0]?.refundableStatus ===
                    'REFUNDABLE'
                        ? 'Refundable'
                        : 'Non-Refundable'
                singleReturnData.price = Number(
                    selectedReturnFlight?.journeys[0]?.fareInfo?.netToAgent
                        ?.adultFare?.amount
                )
                singleReturnData.subtotal = getRoundTripReturnPriceTotal()

                singleDepartureData.addOns = []
                selectedDepartureFlightBaggage?.forEach((data) => {
                    singleDepartureData.addOns.push({
                        type: data?.type,
                        sourceAirport: data?.sourceAirport
                            ? data?.sourceAirport
                            : '',
                        destinationAirport: data?.destinationAirport
                            ? data?.destinationAirport
                            : '',
                        marketing_airline: data?.marketing_airline
                            ? data?.marketing_airline
                            : '',
                        operating_airline: data?.operating_airline
                            ? data?.operating_airline
                            : '',
                        baggage_type: data?.baggageType,
                        baggage_quantity: data?.baggageQuantity,
                        bagagge_weight: data?.baggageWeight,
                        baggage_price: data?.netToAgent?.amount,
                        baggage_currency: data?.netToAgent?.currency,
                        meal_quantity: '',
                        meal_display_name: '',
                        meal_price: '',
                        meal_currency: '',
                        baggage_add_ons_id: data?.id,
                        meal_add_ons_id: '',
                    })
                })

                selectedDepartureMeals.forEach((data) => {
                    singleDepartureData.addOns.push({
                        type: data?.type,
                        sourceAirport: data?.sourceAirport
                            ? data?.sourceAirport
                            : '',
                        destinationAirport: data?.destinationAirport
                            ? data?.destinationAirport
                            : '',
                        marketing_airline: data?.marketing_airline
                            ? data?.marketing_airline
                            : '',
                        operating_airline: data?.operating_airline
                            ? data?.operating_airline
                            : '',
                        baggage_type: '',
                        baggage_quantity: '',
                        bagagge_weight: '',
                        baggage_price: '',
                        baggage_currency: '',
                        baggage_add_ons_id: '',
                        meal_quantity: data?.quantity,
                        meal_display_name: data?.displayName,
                        meal_price: data?.priceWithCurrency?.amount,
                        meal_currency: data?.priceWithCurrency?.currency,
                        meal_add_ons_id: data?.id,
                    })
                })

                singleReturnData.addOns = []
                selectedReturnFlightBaggage?.map((data) => {
                    singleReturnData.addOns.push({
                        type: data?.type,
                        sourceAirport: data?.sourceAirport
                            ? data?.sourceAirport
                            : '',
                        destinationAirport: data?.destinationAirport
                            ? data?.destinationAirport
                            : '',
                        marketing_airline: data?.marketing_airline
                            ? data?.marketing_airline
                            : '',
                        operating_airline: data?.operating_airline
                            ? data?.operating_airline
                            : '',
                        baggage_type: data?.baggageType,
                        baggage_quantity: data?.baggageQuantity,
                        bagagge_weight: data?.baggageWeight,
                        baggage_price: data?.netToAgent?.amount,
                        baggage_currency: data?.netToAgent?.currency,
                        meal_quantity: '',
                        meal_display_name: '',
                        meal_price: '',
                        meal_currency: '',
                        baggage_add_ons_id: data?.id,
                        meal_add_ons_id: '',
                    })
                })

                selectedReturnMeeals.forEach((data) => {
                    singleReturnData.addOns.push({
                        type: data?.type,
                        sourceAirport: data?.sourceAirport
                            ? data?.sourceAirport
                            : '',
                        destinationAirport: data?.destinationAirport
                            ? data?.destinationAirport
                            : '',
                        marketing_airline: data?.marketing_airline
                            ? data?.marketing_airline
                            : '',
                        operating_airline: data?.operating_airline
                            ? data?.operating_airline
                            : '',
                        baggage_type: '',
                        baggage_quantity: '',
                        bagagge_weight: '',
                        baggage_price: '',
                        baggage_currency: '',
                        baggage_add_ons_id: '',
                        meal_quantity: data?.quantity,
                        meal_display_name: data?.displayName,
                        meal_price: data?.priceWithCurrency?.amount,
                        meal_currency: data?.priceWithCurrency?.currency,
                        meal_add_ons_id: data?.id,
                    })
                })

                roundTripData.departure.push(singleDepartureData)
                roundTripData.return.push(singleReturnData)
            })
        }
        if (tickets?.bookingFlightType === 'smart_combo') {
            trip?.passenger?.map((passenger) => {
                let singleDepartureData = {}
                let singleReturnData = {}

                singleDepartureData.pessenger_name =
                    passenger?.passenger_name?.name
                singleDepartureData.rc_code = '-'
                singleDepartureData.title = `${
                    selectedDepartureFlight?.departureDetail?.airportCode
                } - ${selectedDepartureFlight?.arrivalDetail?.airportCode} (${
                    selectedDepartureFlight?.numOfTransits === '0'
                        ? 'direct'
                        : 'transit'
                })`
                singleDepartureData.details =
                    selectedDepartureFlight?.airlineDetail[0]?.airlineName
                singleDepartureData.date_time = `${selectedDepartureFlight?.departureDetail?.departureDate} ${selectedDepartureFlight?.departureDetail?.departureTime} - ${selectedDepartureFlight?.arrivalDetail?.arrivalDate} ${selectedDepartureFlight?.arrivalDetail?.arrivalTime}`
                singleDepartureData.status = 'On Booking'
                singleDepartureData.info = 'On Booking'
                singleDepartureData.additional_info =
                    selectedDepartureFlight?.refundableStatus
                singleDepartureData.price = getSmartComboDepartureFlightPrice()
                singleDepartureData.subtotal =
                    getSmartComboDepartureTotalPrice()

                singleReturnData.pessenger_name =
                    passenger?.passenger_name?.name
                singleReturnData.rc_code = '-'
                singleReturnData.title = `${
                    selectedReturnFlight?.departureDetail?.airportCode
                } - ${selectedReturnFlight?.arrivalDetail?.airportCode} (${
                    selectedReturnFlight?.numOfTransits === '0'
                        ? 'direct'
                        : 'transit'
                })`
                singleReturnData.details =
                    selectedReturnFlight?.airlineDetail[0]?.airlineName
                singleReturnData.date_time = `${selectedReturnFlight?.departureDetail?.departureDate} ${selectedReturnFlight?.departureDetail?.departureTime} - ${selectedReturnFlight?.arrivalDetail?.arrivalDate} ${selectedReturnFlight?.arrivalDetail?.arrivalTime}`
                singleReturnData.status = 'On Booking'
                singleReturnData.info = 'On Booking'
                singleReturnData.additional_info =
                    selectedReturnFlight?.refundableStatus
                singleReturnData.price = getSmartComboReturnFlightPrice()
                singleReturnData.subtotal = getSmartComboReturnTotalPrice()

                singleDepartureData.addOns = []
                selectedDepartureFlightBaggage?.forEach((data) => {
                    singleDepartureData.addOns.push({
                        type: data?.type,
                        sourceAirport: data?.sourceAirport
                            ? data?.sourceAirport
                            : '',
                        destinationAirport: data?.destinationAirport
                            ? data?.destinationAirport
                            : '',
                        marketing_airline: data?.marketing_airline
                            ? data?.marketing_airline
                            : '',
                        operating_airline: data?.operating_airline
                            ? data?.operating_airline
                            : '',
                        baggage_type: data?.baggageType,
                        baggage_quantity: data?.baggageQuantity,
                        bagagge_weight: data?.baggageWeight,
                        baggage_price: data?.netToAgent?.amount,
                        baggage_currency: data?.netToAgent?.currency,
                        meal_quantity: '',
                        meal_display_name: '',
                        meal_price: '',
                        meal_currency: '',
                        baggage_add_ons_id: data?.id,
                        meal_add_ons_id: '',
                    })
                })

                selectedDepartureMeals.forEach((data) => {
                    singleDepartureData.addOns.push({
                        type: data?.type,
                        sourceAirport: data?.sourceAirport
                            ? data?.sourceAirport
                            : '',
                        destinationAirport: data?.destinationAirport
                            ? data?.destinationAirport
                            : '',
                        marketing_airline: data?.marketing_airline
                            ? data?.marketing_airline
                            : '',
                        operating_airline: data?.operating_airline
                            ? data?.operating_airline
                            : '',
                        baggage_type: '',
                        baggage_quantity: '',
                        bagagge_weight: '',
                        baggage_price: '',
                        baggage_currency: '',
                        baggage_add_ons_id: '',
                        meal_quantity: data?.quantity,
                        meal_display_name: data?.displayName,
                        meal_price: data?.priceWithCurrency?.amount,
                        meal_currency: data?.priceWithCurrency?.currency,
                        meal_add_ons_id: data?.id,
                    })
                })

                singleReturnData.addOns = []
                selectedReturnFlightBaggage?.map((data) => {
                    singleReturnData.addOns.push({
                        type: data?.type,
                        sourceAirport: data?.sourceAirport
                            ? data?.sourceAirport
                            : '',
                        destinationAirport: data?.destinationAirport
                            ? data?.destinationAirport
                            : '',
                        marketing_airline: data?.marketing_airline
                            ? data?.marketing_airline
                            : '',
                        operating_airline: data?.operating_airline
                            ? data?.operating_airline
                            : '',
                        baggage_type: data?.baggageType,
                        baggage_quantity: data?.baggageQuantity,
                        bagagge_weight: data?.baggageWeight,
                        baggage_price: data?.netToAgent?.amount,
                        baggage_currency: data?.netToAgent?.currency,
                        meal_quantity: '',
                        meal_display_name: '',
                        meal_price: '',
                        meal_currency: '',
                        baggage_add_ons_id: data?.id,
                        meal_add_ons_id: '',
                    })
                })

                selectedReturnMeeals.forEach((data) => {
                    singleReturnData.addOns.push({
                        type: data?.type,
                        sourceAirport: data?.sourceAirport
                            ? data?.sourceAirport
                            : '',
                        destinationAirport: data?.destinationAirport
                            ? data?.destinationAirport
                            : '',
                        marketing_airline: data?.marketing_airline
                            ? data?.marketing_airline
                            : '',
                        operating_airline: data?.operating_airline
                            ? data?.operating_airline
                            : '',
                        baggage_type: '',
                        baggage_quantity: '',
                        bagagge_weight: '',
                        baggage_price: '',
                        baggage_currency: '',
                        baggage_add_ons_id: '',
                        meal_quantity: data?.quantity,
                        meal_display_name: data?.displayName,
                        meal_price: data?.priceWithCurrency?.amount,
                        meal_currency: data?.priceWithCurrency?.currency,
                        meal_add_ons_id: data?.id,
                    })
                })

                smartComboData.departure.push(singleDepartureData)
                smartComboData.return.push(singleReturnData)
            })
        }
        if (tickets?.isBookHotel) {
            trip?.passenger?.map((passenger) => {
                let singleHotelData = {}

                singleHotelData.guest_name = passenger?.passenger_name?.name
                singleHotelData.rc_code = '-'
                singleHotelData.location =
                    selectedHotel?.hotel?.propertySummary?.address?.city
                singleHotelData.hotel =
                    selectedHotel?.hotel?.propertySummary?.name
                singleHotelData.check_in = {
                    timestamp: trip?.hotel_check_in,
                    string: `${format(
                        new Date(trip?.hotel_check_in),
                        'EEEE, dd MMM yyyy'
                    )} dari 14:00`,
                }
                singleHotelData.check_out = {
                    timestamp: trip?.hotel_check_out,
                    string: `${format(
                        new Date(trip?.hotel_check_out),
                        'EEEE, dd MMM yyyy'
                    )} sebelum 12:00`,
                }
                singleHotelData.duration = trip?.hotel_durasi
                singleHotelData.hotel_price = Number(
                    selectedHotel?.room?.rates?.chargeableRate?.total
                )

                hotelData.push(singleHotelData)
            })
        }

        const payload = {
            data: {
                summaryRoundTrip: roundTripData,
                summaryOneWay: notRoundTripData,
                summaryHotel: hotelData,
                summaryPackageRoundTrip: smartComboData,

                selectedOneWay: selectedFlight,
                selectedRoundTrip: {
                    departure:
                        tickets?.bookingFlightType === 'round_trip'
                            ? selectedDepartureFlight
                            : null,
                    return:
                        tickets?.bookingFlightType === 'round_trip'
                            ? selectedReturnFlight
                            : null,
                },
                selectedPackageRoundTrip: {
                    departure:
                        tickets?.bookingFlightType === 'smart_combo'
                            ? selectedDepartureFlight
                            : null,
                    return:
                        tickets?.bookingFlightType === 'smart_combo'
                            ? selectedReturnFlight
                            : null,
                },
                selectedHotel,
            },
            isBookHotel: tickets?.isBookHotel,
            bookingFlightType: tickets?.bookingFlightType,
            idTrip: searchParams.get('id'),
            total: handleCalculateTotalPrice(),
        }
        console.log({ payload })
        return await sendSummary(payload)
    }

    const submitSummary = useMutation(handleSubmit, {
        onSuccess: () => {
            onSuccess()
            // window.location.href = process.env.REACT_APP_REDIRECT_URL
        },
    })

    let departureTitle = ''
    let departureDetails = ''
    let departureDateTime = ''
    let departureAdditionalInfo = ''
    let departurePrice = ''
    let departureSubtotal = ''

    let returnTitle = ''
    let returnDetails = ''
    let returnDateTime = ''
    let returnAdditionalInfo = ''
    let returnPrice = ''
    let returnSubtotal = ''

    if (tickets?.bookingFlightType === 'smart_combo') {
        departureTitle =
            selectedDepartureFlight?.departureDetail?.airportCode +
            '-' +
            selectedDepartureFlight?.arrivalDetail?.airportCode +
            ' ' +
            (selectedDepartureFlight?.numOfTransits === '0'
                ? 'direct'
                : 'transit')
        departureDetails =
            selectedDepartureFlight?.airlineDetail[0]?.airlineName
        departureDateTime =
            selectedDepartureFlight?.departureDetail?.departureDate +
            ' ' +
            selectedDepartureFlight?.departureDetail?.departureTime +
            ' - ' +
            selectedDepartureFlight?.arrivalDetail?.arrivalDate +
            ' ' +
            selectedDepartureFlight?.arrivalDetail?.arrivalTime
        departureAdditionalInfo = selectedDepartureFlight?.refundableStatus
        departurePrice = selectedDepartureFlight?.fare * trip?.passenger?.length
        departureSubtotal =
            selectedDepartureFlight?.fare * trip?.passenger?.length +
            selectedDepartureFlightBaggage.reduce(
                (accumulator, current) =>
                    accumulator + parseFloat(current.netToAgent?.amount),
                0
            ) *
                trip?.passenger?.length +
            selectedDepartureMeals.reduce(
                (accumulator, current) =>
                    accumulator + parseFloat(current.netToAgent?.amount),
                0
            ) *
                trip?.passenger?.length

        returnTitle =
            selectedReturnFlight?.departureDetail?.airportCode +
            '-' +
            selectedReturnFlight?.arrivalDetail?.airportCode +
            ' ' +
            (selectedReturnFlight?.numOfTransits === '0' ? 'direct' : 'transit')
        returnDetails = selectedReturnFlight?.airlineDetail[0]?.airlineName
        returnDateTime =
            selectedReturnFlight?.departureDetail?.departureDate +
            ' ' +
            selectedReturnFlight?.departureDetail?.departureTime +
            ' - ' +
            selectedReturnFlight?.arrivalDetail?.arrivalDate +
            ' ' +
            selectedReturnFlight?.arrivalDetail?.arrivalTime
        returnAdditionalInfo = selectedReturnFlight?.refundableStatus
        returnPrice = selectedReturnFlight?.fare * trip?.passenger?.length
        returnSubtotal =
            selectedReturnFlight?.fare * trip?.passenger?.length +
            selectedReturnFlightBaggage.reduce(
                (accumulator, current) =>
                    accumulator + parseFloat(current.netToAgent?.amount),
                0
            ) *
                trip?.passenger?.length +
            selectedDepartureMeals.reduce(
                (accumulator, current) =>
                    accumulator + parseFloat(current.netToAgent?.amount),
                0
            ) *
                trip?.passenger?.length
    } else if (tickets?.bookingFlightType === 'round_trip') {
        departureTitle =
            selectedDepartureFlight?.departureAirport +
            '-' +
            selectedDepartureFlight?.arrivalAirport +
            ' ' +
            (selectedDepartureFlight?.numOfTransits === '0'
                ? 'direct'
                : 'transit')
        departureDetails =
            selectedDepartureFlight?.airlineDetail[0]?.airlineName
        departureDateTime =
            selectedReturnFlight?.journeys[0]?.departureDetail?.departureDate +
            ' ' +
            selectedReturnFlight?.journeys[0]?.departureDetail?.departureTime +
            ' - ' +
            selectedReturnFlight?.journeys[0]?.arrivalDetail?.arrivalDate +
            ' ' +
            selectedReturnFlight?.journeys[0]?.arrivalDetail?.arrivalTime
        departureAdditionalInfo =
            selectedDepartureFlight?.journeys[0]?.refundableStatus ===
            'REFUNDABLE'
                ? 'Refundable'
                : 'Non-Refundable'
        departurePrice =
            Number(
                selectedDepartureFlight?.journeys[0]?.fareInfo?.netToAgent
                    ?.adultFare?.amount
            ) * trip?.passenger?.length
        departureSubtotal =
            Number(
                selectedDepartureFlight?.journeys[0]?.fareInfo?.netToAgent
                    ?.adultFare?.amount
            ) *
                trip?.passenger?.length +
            Number(
                selectedDepartureFlightBaggage.reduce(
                    (accumulator, current) =>
                        accumulator + parseFloat(current.netToAgent?.amount),
                    0
                )
            ) *
                trip?.passenger?.length +
            Number(
                selectedDepartureMeals.reduce(
                    (accumulator, current) =>
                        accumulator + parseFloat(current.netToAgent?.amount),
                    0
                )
            ) *
                trip?.passenger?.length

        returnTitle =
            selectedReturnFlight?.departureAirport +
            '-' +
            selectedReturnFlight?.arrivalAirport +
            ' ' +
            (selectedReturnFlight?.numOfTransits === '0' ? 'direct' : 'transit')
        returnDetails = selectedReturnFlight?.airlineDetail[0]?.airlineName
        returnDateTime =
            selectedReturnFlight?.journeys[0]?.departureDetail?.departureDate +
            ' ' +
            selectedReturnFlight?.journeys[0]?.departureDetail?.departureTime +
            ' - ' +
            selectedReturnFlight?.journeys[0]?.arrivalDetail?.arrivalDate +
            ' ' +
            selectedReturnFlight?.journeys[0]?.arrivalDetail?.arrivalTime
        returnAdditionalInfo =
            selectedReturnFlight?.journeys[0]?.refundableStatus === 'REFUNDABLE'
                ? 'Refundable'
                : 'Non-Refundable'
        returnPrice =
            Number(
                selectedReturnFlight?.journeys[0]?.fareInfo?.netToAgent
                    ?.adultFare?.amount
            ) * trip?.passenger?.length
        returnSubtotal =
            Number(
                selectedReturnFlight?.journeys[0]?.fareInfo?.netToAgent
                    ?.adultFare?.amount
            ) *
                trip?.passenger?.length +
            Number(
                selectedReturnFlightBaggage.reduce(
                    (accumulator, current) =>
                        accumulator + parseFloat(current.netToAgent?.amount),
                    0
                )
            ) *
                trip?.passenger?.length +
            Number(
                selectedReturnMeeals.reduce(
                    (accumulator, current) =>
                        accumulator + parseFloat(current.netToAgent?.amount),
                    0
                )
            ) *
                trip?.passenger?.length
    }

    useEffect(() => {
        if (isOpen) {
            console.log({
                selectedFlight,
                selectedDepartureFlight,
                selectedReturnFlight,
                selectedFlightBaggage,
                selectedDepartureFlightBaggage,
                selectedReturnFlightBaggage,
                selectedHotel,
                trip,
                tickets,
            })
        }
    }, [isOpen])

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'6xl'}>
            <ModalOverlay />
            <ModalContent margin="10px">
                <ModalHeader
                    as={Flex}
                    alignItems={'center'}
                    gap={'10px'}
                    borderBottomWidth={'2px'}
                    borderBottomColor={'gray'}
                    marginX={'20px'}
                    paddingX={'0px'}
                >
                    <Text>Summary</Text>
                </ModalHeader>
                <ModalCloseButton top={'15px'} />
                <ModalBody as={Flex} flexDirection={'column'} gap={'10px'}>
                    {tickets?.bookingFlightType === 'one_way' ? (
                        <TableContainer>
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>Pessenger Name</Th>
                                        <Th>Title</Th>
                                        <Th>Details</Th>
                                        <Th>Date Time</Th>
                                        <Th>Additional Info</Th>
                                        <Th>Price</Th>
                                        <Th>Subtotal</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {trip?.passenger?.map(
                                        (passenger, index) => {
                                            return (
                                                <Tr key={index}>
                                                    <Td>
                                                        {
                                                            passenger
                                                                ?.passenger_name
                                                                ?.name
                                                        }
                                                    </Td>
                                                    <Td>
                                                        {
                                                            selectedFlight?.departureAirport
                                                        }{' '}
                                                        -{' '}
                                                        {
                                                            selectedFlight?.arrivalAirport
                                                        }{' '}
                                                        (
                                                        {selectedFlight?.numOfTransits ===
                                                        '0'
                                                            ? 'direct'
                                                            : 'transit'}
                                                        )
                                                    </Td>
                                                    <Td>
                                                        {
                                                            selectedFlight
                                                                ?.airlineDetail[0]
                                                                ?.airlineName
                                                        }
                                                    </Td>
                                                    <Td>
                                                        {
                                                            selectedFlight
                                                                ?.journeys[0]
                                                                ?.departureDetail
                                                                ?.departureDate
                                                        }{' '}
                                                        (
                                                        {
                                                            selectedFlight
                                                                ?.journeys[0]
                                                                ?.departureDetail
                                                                ?.departureTime
                                                        }{' '}
                                                        -{' '}
                                                        {
                                                            selectedFlight
                                                                ?.journeys[0]
                                                                ?.arrivalDetail
                                                                ?.arrivalTime
                                                        }
                                                        )
                                                    </Td>
                                                    <Td>
                                                        {selectedFlight
                                                            ?.journeys[0]
                                                            ?.refundableStatus ===
                                                        'REFUNDABLE'
                                                            ? 'Refundable'
                                                            : 'Non-Refundable'}
                                                    </Td>
                                                    <Td>
                                                        {
                                                            selectedFlight
                                                                ?.journeys[0]
                                                                ?.fareInfo
                                                                ?.netToAgent
                                                                ?.adultFare
                                                                ?.currency
                                                        }
                                                        .{' '}
                                                        {Number(
                                                            selectedFlight
                                                                ?.journeys[0]
                                                                ?.fareInfo
                                                                ?.netToAgent
                                                                ?.adultFare
                                                                ?.amount
                                                        ).toLocaleString()}
                                                    </Td>
                                                    <Td>
                                                        {
                                                            selectedFlight
                                                                ?.journeys[0]
                                                                ?.fareInfo
                                                                ?.netToAgent
                                                                ?.adultFare
                                                                ?.currency
                                                        }
                                                        .
                                                        {Number(
                                                            Number(
                                                                selectedFlight
                                                                    ?.journeys[0]
                                                                    ?.fareInfo
                                                                    ?.netToAgent
                                                                    ?.adultFare
                                                                    ?.amount
                                                            ) *
                                                                trip?.passenger
                                                                    ?.length +
                                                                Number(
                                                                    selectedFlightBaggage.reduce(
                                                                        (
                                                                            accumulator,
                                                                            current
                                                                        ) =>
                                                                            accumulator +
                                                                            parseFloat(
                                                                                current
                                                                                    .netToAgent
                                                                                    ?.amount
                                                                            ),
                                                                        0
                                                                    )
                                                                ) *
                                                                    trip
                                                                        ?.passenger
                                                                        ?.length +
                                                                Number(
                                                                    selectedMeals.reduce(
                                                                        (
                                                                            accumulator,
                                                                            current
                                                                        ) =>
                                                                            accumulator +
                                                                            parseFloat(
                                                                                current
                                                                                    .netToAgent
                                                                                    ?.amount
                                                                            ),
                                                                        0
                                                                    )
                                                                ) *
                                                                    trip
                                                                        ?.passenger
                                                                        ?.length
                                                        ).toLocaleString('id')}
                                                    </Td>
                                                </Tr>
                                            )
                                        }
                                    )}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    ) : null}

                    {tickets?.bookingFlightType === 'round_trip' ||
                    tickets?.bookingFlightType === 'smart_combo' ? (
                        <TableContainer>
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>Pessenger Name</Th>
                                        <Th>Title</Th>
                                        <Th>Details</Th>
                                        <Th>Date Time</Th>
                                        <Th>Additional Info</Th>
                                        <Th>Price</Th>
                                        <Th>Subtotal</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {trip?.passenger?.map(
                                        (passenger, index) => {
                                            return (
                                                <>
                                                    <Tr>
                                                        <Td>
                                                            {
                                                                passenger
                                                                    ?.passenger_name
                                                                    ?.name
                                                            }
                                                        </Td>
                                                        <Td>
                                                            {departureTitle}
                                                        </Td>
                                                        <Td>
                                                            {departureDetails}
                                                        </Td>
                                                        <Td>
                                                            {departureDateTime}
                                                        </Td>
                                                        <Td>
                                                            {
                                                                departureAdditionalInfo
                                                            }
                                                        </Td>
                                                        <Td>
                                                            IDR.{' '}
                                                            {departurePrice?.toLocaleString(
                                                                'id'
                                                            )}
                                                        </Td>
                                                        <Td>
                                                            IDR.{' '}
                                                            {departureSubtotal?.toLocaleString(
                                                                'id'
                                                            )}
                                                        </Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td>
                                                            {
                                                                passenger
                                                                    ?.passenger_name
                                                                    ?.name
                                                            }
                                                        </Td>
                                                        <Td>{returnTitle}</Td>
                                                        <Td>{returnDetails}</Td>
                                                        <Td>
                                                            {returnDateTime}
                                                        </Td>
                                                        <Td>
                                                            {
                                                                returnAdditionalInfo
                                                            }
                                                        </Td>
                                                        <Td>
                                                            IDR.{' '}
                                                            {returnPrice?.toLocaleString(
                                                                'id'
                                                            )}
                                                        </Td>
                                                        <Td>
                                                            IDR.{' '}
                                                            {returnSubtotal?.toLocaleString(
                                                                'id'
                                                            )}
                                                        </Td>
                                                    </Tr>
                                                </>
                                            )
                                        }
                                    )}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    ) : null}

                    {tickets?.isBookHotel ? (
                        <TableContainer>
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>Guest Name</Th>
                                        <Th>Location</Th>
                                        <Th>Hotel</Th>
                                        <Th>Room</Th>
                                        <Th>Check In</Th>
                                        <Th>Check Out</Th>
                                        <Th>Duration</Th>
                                        <Th>Total Room</Th>
                                        <Th>Base Price</Th>
                                        <Th>Total Price</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td>
                                            {trip?.guest[0]?.guest_name?.name}
                                        </Td>
                                        <Td>
                                            {
                                                selectedHotel?.hotel
                                                    ?.propertySummary?.address
                                                    ?.city
                                            }
                                        </Td>
                                        <Td>
                                            {
                                                selectedHotel?.hotel
                                                    ?.propertySummary?.name
                                            }
                                        </Td>
                                        <Td>{selectedHotel?.room?.name}</Td>
                                        <Td>
                                            {format(
                                                new Date(trip?.hotel_check_in),
                                                'EEEE, dd MMM yyyy'
                                            )}{' '}
                                            dari 14:00
                                        </Td>
                                        <Td>
                                            {format(
                                                new Date(trip?.hotel_check_out),
                                                'EEEE, dd MMM yyyy'
                                            )}{' '}
                                            sebelum 12:00
                                        </Td>
                                        <Td>{trip?.hotel_durasi} Night</Td>
                                        <Td>{trip?.jumlah_kamar} Room</Td>
                                        <Td>
                                            IDR.{' '}
                                            {Number(
                                                selectedHotel?.room?.rates
                                                    ?.chargeableRate?.total
                                            ).toLocaleString()}
                                        </Td>
                                        <Td>
                                            IDR.{' '}
                                            {Number(
                                                Number(
                                                    selectedHotel?.room?.rates
                                                        ?.chargeableRate?.total
                                                ) *
                                                    Number(trip?.hotel_durasi) *
                                                    Number(trip?.jumlah_kamar)
                                            ).toLocaleString()}
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                    ) : null}

                    <Text marginTop={'24px'} textAlign={'right'}>
                        <Box
                            as={'span'}
                            fontWeight={'semibold'}
                            marginRight={'10px'}
                        >
                            Total Price: IDR.{' '}
                            {handleCalculateTotalPrice().toLocaleString()}
                        </Box>
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <Button variant="ghost" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        colorScheme="blue"
                        onClick={() => {
                            submitSummary.mutate()
                            // handleSubmit()
                        }}
                        isLoading={submitSummary.isLoading}
                    >
                        Submit
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default ModalSummary
