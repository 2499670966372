import React, { useEffect, useState } from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import RoomItem from './RoomItem'
import GoogleMapReact from 'google-map-react'
import { format } from 'date-fns'
import { getHotelRooms } from '../../../api/requests/oos'
import { useMutation } from 'react-query'
import { Swiper, SwiperSlide } from 'swiper/react'
import { MdStar, MdLocationOn, MdClose } from 'react-icons/md'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Flex,
    Icon,
    Text,
    VStack,
    Divider,
    HStack,
    Image,
    Skeleton,
    Box,
    Center,
    Stack,
} from '@chakra-ui/react'

const AnyReactComponent = () => (
    <Icon as={MdLocationOn} fontSize="36px" color="blue.400" />
)

export default function ModalHotelRooms({
    isOpen,
    onClose,
    previewHotel,
    trip,
    setSelectedHotel,
}) {
    const [showHotelImages, setshowHotelImages] = useState(false)
    const [showRoomImages, setShowRoomImages] = useState(false)
    const [roomImages, setRoomImages] = useState([])

    const defaultProps = {
        center: {
            lat: Number(previewHotel?.propertySummary?.geoLocation?.lat),
            lng: Number(previewHotel?.propertySummary?.geoLocation?.lon),
        },
        zoom: 15,
    }

    const hotelRooms = useMutation(getHotelRooms)

    useEffect(() => {
        hotelRooms.mutate({
            propertyId: previewHotel?.propertyId,
            checkInDate: format(new Date(trip?.hotel_check_in), 'yyyy-MM-dd'),
            checkOutDate: format(new Date(trip?.hotel_check_out), 'yyyy-MM-dd'),
            numOfAdults: `${trip?.passenger?.length}`,
        })
    }, [previewHotel])

    return (
        <Modal
            isOpen={isOpen}
            size="4xl"
            onClose={() => {
                setshowHotelImages(false)
                setShowRoomImages(false)
                setRoomImages([])
                onClose()
            }}
        >
            <ModalOverlay />
            <ModalContent margin="10px" paddingBottom="30px">
                <ModalHeader></ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack spacing="5px" align="flex-start">
                        {hotelRooms?.data?.propertyImages?.length > 0 && (
                            <Stack direction="row" width="100%">
                                <Stack
                                    width="75%"
                                    height="368px"
                                    spacing="10px"
                                >
                                    {hotelRooms?.data?.propertyImages[0]
                                        ?.entries[
                                        hotelRooms?.data?.propertyImages[0]
                                            ?.entries?.length - 1
                                    ]?.url ? (
                                        <Image
                                            cursor="pointer"
                                            onClick={() =>
                                                setshowHotelImages(true)
                                            }
                                            src={
                                                hotelRooms?.data
                                                    ?.propertyImages[0]
                                                    ?.entries[
                                                    hotelRooms?.data
                                                        ?.propertyImages[0]
                                                        ?.entries?.length - 1
                                                ]?.url
                                            }
                                            width="100%"
                                            height="368px"
                                            objectFit="cover"
                                            rounded="md"
                                        />
                                    ) : null}
                                </Stack>
                                <Stack spacing="10px">
                                    {hotelRooms?.data?.propertyImages?.map(
                                        (pic, index) => {
                                            if (index >= 1 && index <= 4) {
                                                return (
                                                    <Image
                                                        cursor="pointer"
                                                        onClick={() =>
                                                            setshowHotelImages(
                                                                true
                                                            )
                                                        }
                                                        src={
                                                            pic?.entries[
                                                                pic?.entries
                                                                    ?.length - 1
                                                            ]?.url
                                                        }
                                                        width="100%"
                                                        height="116px"
                                                        objectFit="cover"
                                                        rounded="md"
                                                    />
                                                )
                                            }
                                        }
                                    )}
                                </Stack>
                            </Stack>
                        )}
                        <HStack alignItems="center">
                            <VStack spacing="5px" align="flex-start">
                                <Text fontSize="xl" fontWeight="bold">
                                    {previewHotel?.propertySummary?.name}
                                </Text>
                                <Stack direction="row">
                                    {[
                                        ...Array(
                                            Number(
                                                previewHotel?.propertySummary
                                                    ?.starRating
                                            )
                                        ),
                                    ]?.map((_, index) => {
                                        return (
                                            <Icon
                                                key={index}
                                                as={MdStar}
                                                color="orange.400"
                                            />
                                        )
                                    })}
                                </Stack>
                                <Flex alignItems="center">
                                    <Icon
                                        as={MdLocationOn}
                                        color="gray.400"
                                        fontSize="24px"
                                    />
                                    <Text>
                                        {
                                            previewHotel?.propertySummary
                                                ?.address?.city
                                        }
                                        ,{' '}
                                        {
                                            previewHotel?.propertySummary
                                                ?.address?.countryName
                                        }
                                    </Text>
                                </Flex>
                                <Text>
                                    {
                                        previewHotel?.propertySummary
                                            ?.localAddress?.lines[0]
                                    }
                                    ,{' '}
                                    {
                                        previewHotel?.propertySummary
                                            ?.localAddress?.city
                                    }
                                    ,{' '}
                                    {
                                        previewHotel?.propertySummary
                                            ?.localAddress?.countryName
                                    }
                                </Text>
                            </VStack>
                        </HStack>

                        <Divider />
                        <Flex height="50vh" width="100%" rounded="md">
                            <GoogleMapReact
                                bootstrapURLKeys={{
                                    key: process.env
                                        .REACT_APP_GOOGLE_MAPS_API_KEY,
                                }}
                                defaultCenter={defaultProps.center}
                                defaultZoom={defaultProps.zoom}
                            >
                                <AnyReactComponent
                                    lat={Number(
                                        previewHotel?.propertySummary
                                            ?.geoLocation?.lat
                                    )}
                                    lng={Number(
                                        previewHotel?.propertySummary
                                            ?.geoLocation?.lon
                                    )}
                                />
                            </GoogleMapReact>
                        </Flex>
                        <Divider />
                        <Flex gap="10px" width="100%">
                            <Flex
                                justifyContent="flex-start"
                                flexDirection="column"
                                width="50%"
                                height="300px"
                                overflowY="auto"
                            >
                                <Text fontWeight="semibold" textAlign="center">
                                    Description
                                </Text>
                                <Text
                                    fontSize="14px"
                                    marginTop="10px"
                                    fontWeight="semibold"
                                >
                                    Check-in:
                                </Text>
                                {hotelRooms?.isLoading ? (
                                    <Skeleton height="20px" width="50px" />
                                ) : (
                                    <Text fontSize="14px">
                                        {
                                            hotelRooms?.data?.propertyDetail
                                                ?.checkInTime
                                        }
                                    </Text>
                                )}
                                <Text
                                    fontSize="14px"
                                    marginTop="10px"
                                    fontWeight="semibold"
                                >
                                    Check-out:
                                </Text>
                                {hotelRooms?.isLoading ? (
                                    <Skeleton height="20px" width="50px" />
                                ) : (
                                    <Text fontSize="14px">
                                        {
                                            hotelRooms?.data?.propertyDetail
                                                ?.checkOutTime
                                        }
                                    </Text>
                                )}
                                <Text
                                    fontSize="14px"
                                    marginTop="10px"
                                    fontWeight="semibold"
                                >
                                    Check-in instruction:
                                </Text>
                                {hotelRooms?.isLoading ? (
                                    <Flex flexDirection="column" gap="2.5px">
                                        <Skeleton height="20px" width="100%" />
                                        <Skeleton height="20px" width="100%" />
                                        <Skeleton height="20px" width="100%" />
                                    </Flex>
                                ) : (
                                    <Text fontSize="14px">
                                        {hotelRooms?.data?.propertyDetail?.checkInInstruction.replace(
                                            /<[^>]+>/g,
                                            ''
                                        )}
                                    </Text>
                                )}
                                <Text
                                    fontSize="14px"
                                    marginTop="10px"
                                    fontWeight="semibold"
                                >
                                    Important notice:
                                </Text>
                                {hotelRooms?.isLoading ? (
                                    <Flex flexDirection="column" gap="2.5px">
                                        <Skeleton height="20px" width="100%" />
                                        <Skeleton height="20px" width="100%" />
                                        <Skeleton height="20px" width="100%" />
                                    </Flex>
                                ) : (
                                    <Text fontSize="14px">
                                        {hotelRooms?.data?.propertyDetail?.importanceNotice.replace(
                                            /<[^>]+>/g,
                                            ''
                                        )}
                                    </Text>
                                )}
                            </Flex>
                            <Flex
                                justifyContent="flex-start"
                                flexDirection="column"
                                width="50%"
                                height="300px"
                                overflowY="auto"
                            >
                                <Text fontWeight="semibold" textAlign="center">
                                    Policy
                                </Text>
                                {hotelRooms?.isLoading ? (
                                    <Flex flexDirection="column" gap="2.5px">
                                        <Skeleton height="20px" width="100%" />
                                        <Skeleton height="20px" width="100%" />
                                        <Skeleton height="20px" width="100%" />
                                    </Flex>
                                ) : (
                                    <Text fontSize="14px">
                                        {hotelRooms?.data?.propertyDetail?.hotelPolicy.replace(
                                            /<[^>]+>/g,
                                            ''
                                        )}
                                    </Text>
                                )}
                            </Flex>
                        </Flex>
                        <Divider />
                        {hotelRooms?.isLoading ? (
                            <Flex
                                flexDirection="column"
                                gap="1rem"
                                width="100%"
                            >
                                <Skeleton height="115px" rounded="base" />
                                <Skeleton height="115px" rounded="base" />
                                <Skeleton height="115px" rounded="base" />
                            </Flex>
                        ) : null}
                        <Stack spacing="1rem" width="100%">
                            {hotelRooms?.data?.rooms?.map((room, index) => {
                                return (
                                    <RoomItem
                                        key={index}
                                        room={room}
                                        onChoose={() => {
                                            setSelectedHotel({
                                                hotel: previewHotel,
                                                room: room,
                                                images: hotelRooms?.data
                                                    ?.propertyImages,
                                            })
                                            onClose()
                                        }}
                                        onClickImage={() => {
                                            setRoomImages(room?.images)
                                            setShowRoomImages(true)
                                        }}
                                    />
                                )
                            })}
                        </Stack>
                    </VStack>

                    {showHotelImages && (
                        <>
                            <Box
                                position="absolute"
                                top="0px"
                                left="0px"
                                right="0px"
                                bottom="0px"
                                backgroundColor="black"
                                opacity="0.5"
                                rounded="md"
                            />
                            <Box
                                as={Swiper}
                                modules={[
                                    Navigation,
                                    Pagination,
                                    Scrollbar,
                                    A11y,
                                ]}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                                position="fixed"
                                top="40px"
                                right="40px"
                                bottom="40px"
                                left="40px"
                            >
                                {hotelRooms?.data?.propertyImages?.map(
                                    (image, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <Image
                                                    objectFit="cover"
                                                    width="100%"
                                                    height="100%"
                                                    src={
                                                        image?.entries[
                                                            image?.entries
                                                                ?.length - 1
                                                        ]?.url
                                                    }
                                                />
                                            </SwiperSlide>
                                        )
                                    }
                                )}
                                <Center
                                    onClick={() => setshowHotelImages(false)}
                                    cursor="pointer"
                                    padding="10px"
                                    position="fixed"
                                    top="10px"
                                    right="20px"
                                    zIndex="999"
                                    rounded="full"
                                    backgroundColor="white"
                                >
                                    <Icon as={MdClose} fontSize="24px" />
                                </Center>
                            </Box>
                        </>
                    )}

                    {showRoomImages && (
                        <>
                            <Box
                                position="absolute"
                                top="0px"
                                left="0px"
                                right="0px"
                                bottom="0px"
                                backgroundColor="black"
                                opacity="0.5"
                                rounded="md"
                            />
                            <Box
                                as={Swiper}
                                modules={[
                                    Navigation,
                                    Pagination,
                                    Scrollbar,
                                    A11y,
                                ]}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                                position="fixed"
                                top="40px"
                                right="40px"
                                bottom="40px"
                                left="40px"
                            >
                                {roomImages?.map((image, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <Image
                                                objectFit="cover"
                                                width="100%"
                                                height="100%"
                                                src={image?.entries[2]?.url}
                                            />
                                        </SwiperSlide>
                                    )
                                })}
                                <Center
                                    cursor="pointer"
                                    padding="10px"
                                    position="fixed"
                                    top="10px"
                                    right="20px"
                                    zIndex="999"
                                    rounded="full"
                                    backgroundColor="white"
                                    onClick={() => {
                                        setShowRoomImages(false)
                                        setRoomImages([])
                                    }}
                                >
                                    <Icon as={MdClose} fontSize="24px" />
                                </Center>
                            </Box>
                        </>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
