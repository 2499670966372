import React from 'react'
import { Box, Flex, Icon, Image, Text } from '@chakra-ui/react'
import { MdOutlineFlightTakeoff, MdOutlineFlightLand } from 'react-icons/md'
import { IoMdCloseCircle } from 'react-icons/io'

function SelectedFlight({ data, onDelete, type }) {
  let logoUrl = ''
  let airlineName = ''
  let flightCode = ''
  let departureAirport = ''
  let departureTime = ''
  let arrivalAirport = ''
  let arrivalTime = ''
  let currency = ''
  let amount = ''

  if (data?.journeys) {
    logoUrl = data?.airlineDetail[0]?.logoUrl
    airlineName = data?.airlineDetail[0]?.airlineName
    flightCode = data?.journeys[0]?.segments[0]?.flightCode
    departureTime = data?.journeys[0]?.departureDetail?.departureTime
    departureAirport = data?.departureAirport
    arrivalTime = data?.journeys[0]?.arrivalDetail?.arrivalTime
    arrivalAirport = data?.arrivalAirport
    currency = data?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.currency
    amount = data?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount
  } else {
    logoUrl = data?.airlineDetail[0]?.logoUrl
    airlineName = data?.airlineDetail[0]?.airlineName
    flightCode = data?.segments[0]?.flightCode
    departureTime = data?.segments[0]?.departureDetail?.departureTime
    departureAirport = data?.departureDetail?.airportCode
    arrivalTime = data?.segments[0]?.arrivalDetail?.arrivalTime
    arrivalAirport = data?.arrivalDetail?.airportCode
    currency = data?.fareCurrency
    amount = data?.fare
  }

  return (
    <Flex
      alignItems={'center'}
      gap={'20px'}
      padding={'20px'}
      rounded={'md'}
      boxShadow={'0px 0px 10px 1px #e8e8e8'}
      width={'max-content'}
      position={'relative'}
    >
      {type === 'departure' ? (
        <MdOutlineFlightTakeoff fontSize={'50px'} />
      ) : (
        <MdOutlineFlightLand fontSize={'50px'} />
      )}
      <Flex flexDirection={'column'}>
        <Flex alignItems={'center'} gap={'10px'} marginBottom={'10px'}>
          <Image height={'40px'} src={logoUrl} objectFit={'cover'} />
          <Text fontWeight={'semibold'}>
            {airlineName} ({flightCode})
          </Text>
        </Flex>
        <Text fontWeight={'medium'}>
          {departureAirport}-{arrivalAirport} | {departureTime}-{arrivalTime}
        </Text>
        <Text fontWeight={'medium'}>
          {currency}. {Number(amount).toLocaleString()}
          <Box as={'span'} fontWeight={'thin'}>
            {' '}
            / pax
          </Box>
        </Text>
      </Flex>
      <Icon
        as={IoMdCloseCircle}
        color={'red'}
        fontSize={'24px'}
        position={'absolute'}
        right={'-10px'}
        top={'-10px'}
        cursor={'pointer'}
        onClick={onDelete}
      />
    </Flex>
  )
}

export default SelectedFlight
