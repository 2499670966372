import React from 'react'
import { Button, Flex, Image, Text } from '@chakra-ui/react'

function FlightItem({ data, onClick, isDisable }) {
  const handleNumberToHoursAndMinutes = (number) => {
    const hours = Math.floor(number / 60)
    const minutes = number % 60
    return hours + 'h' + ' ' + minutes + 'm'
  }

  let logoUrl = ''
  let airlineName = ''
  let flightCode = ''
  let departureTime = ''
  let departureCity = ''
  let departureAirport = ''
  let arrivalTime = ''
  let arrivalCity = ''
  let arrivalAirport = ''
  let flightDuration = 0
  let numOfTransit = ''
  let currency = ''
  let amount = 0
  let refundableStatus = ''
  let mealtOptionLength = 0

  if (data?.journeys) {
    logoUrl = data?.airlineDetail[0]?.logoUrl
    airlineName = data?.airlineDetail[0]?.airlineName
    flightCode = data?.journeys[0]?.segments[0]?.flightCode
    departureTime = data?.journeys[0]?.departureDetail?.departureTime
    departureCity = data?.departureAirportDetail?.city
    departureAirport = data?.departureAirport
    arrivalTime = data?.journeys[0]?.arrivalDetail?.arrivalTime
    arrivalCity = data?.arrivalAirportDetail?.city
    arrivalAirport = data?.arrivalAirport
    flightDuration = data?.journeys[0].journeyDuration
    numOfTransit = data?.numOfTransits
    currency = data?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.currency
    amount = data?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount
    refundableStatus = data?.journeys[0]?.refundableStatus === 'REFUNDABLE'
    mealtOptionLength =
      data?.journeys[0]?.segments[0]?.addOns?.mealOptions?.length
  } else {
    logoUrl = data?.airlineDetail[0]?.logoUrl
    airlineName = data?.airlineDetail[0]?.airlineName
    flightCode = data?.segments[0]?.flightCode
    departureTime = data?.segments[0]?.departureDetail?.departureTime
    departureCity = data?.departureAirportDetail?.city
    departureAirport = data?.departureDetail?.airportCode
    arrivalTime = data?.segments[0]?.arrivalDetail?.arrivalTime
    arrivalCity = data?.arrivalAirportDetail?.city
    arrivalAirport = data?.arrivalDetail?.airportCode
    flightDuration = Number(data?.journeyDuration)
    numOfTransit = data?.numOfTransits
    currency = data?.fareCurrency
    amount = data?.fare
    refundableStatus = data?.segments[0]?.refundableStatus === 'REFUNDABLE'
    mealtOptionLength = data?.segments[0]?.addOns?.mealOptions?.length
  }

  return (
    <Flex
      flexDirection={'column'}
      boxShadow={'0px 0px 10px 1px #e8e8e8'}
      rounded={'md'}
      padding={'20px'}
    >
      <Flex alignItems={'center'} gap={'10px'}>
        <Image height={'40px'} src={logoUrl} objectFit={'cover'} />
        <Text fontWeight={'semibold'}>
          {airlineName} ({flightCode})
        </Text>
      </Flex>
      <Flex gap={'20px'} justifyContent={'space-between'} marginTop={'20px'}>
        <Flex flexDirection={'column'}>
          <Text fontWeight={'semibold'}>{departureTime}</Text>
          <Text fontWeight={'thin'}>
            {departureCity} ({departureAirport})
          </Text>
        </Flex>
        <Flex flexDirection={'column'}>
          <Text fontWeight={'semibold'}>{arrivalTime}</Text>
          <Text fontWeight={'thin'}>
            {arrivalCity} ({arrivalAirport})
          </Text>
        </Flex>
        <Flex flexDirection={'column'}>
          <Text fontWeight={'semibold'}>
            {handleNumberToHoursAndMinutes(flightDuration)}
          </Text>
          <Text fontWeight={'thin'}>
            {numOfTransit === '0' ? 'direct' : 'transit'}
          </Text>
        </Flex>
        <Flex flexDirection={'column'}>
          <Text fontWeight={'semibold'} color={'orange.400'}>
            {currency}. {Number(amount).toLocaleString('id')}
          </Text>
          <Text fontWeight={'thin'}>/pax</Text>
          <Button
            backgroundColor={'orange.400'}
            color={'white'}
            onClick={onClick}
            isDisabled={isDisable}
          >
            Choose
          </Button>
        </Flex>
      </Flex>
      <Flex direction="column">
        {refundableStatus ? (
          <Text color="green.300">REFUNDABLE</Text>
        ) : (
          <Text color="red.300">NOT REFUNDABLE</Text>
        )}
        {mealtOptionLength === 0 ? (
          <Text color="red.300">Allowance Excluded</Text>
        ) : (
          <Text color="green.300">Allowance Included</Text>
        )}
      </Flex>
      <Flex gap={'20px'} marginTop="20px">
        <Text cursor={'pointer'}>Flight Details</Text>
        <Text cursor={'pointer'}>Fare Info</Text>
      </Flex>
    </Flex>
  )
}

export default FlightItem
