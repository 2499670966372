import React from 'react'
import Title from './ModalBaggage/Title'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Text,
  Image,
  Grid,
  GridItem,
  useMediaQuery,
} from '@chakra-ui/react'

function ModalBaggage({
  type,
  isOpen,
  onClose,
  onContinue,
  tickets,
  selectedFlight,
  selectedFlightBaggage,
  setSelectedFlightBaggage,
  selectedDepartureFlight,
  selectedDepartureFlightBaggage,
  setSelectedDepartureFlightBaggage,
  selectedReturnFlight,
  selectedReturnFlightBaggage,
  setSelectedReturnFlightBaggage,
}) {
  const [isLargerThan500] = useMediaQuery('(min-width: 500px)')

  const handleNumberToHoursAndMinutes = (number) => {
    const hours = Math.floor(number / 60)
    const minutes = number % 60
    return hours + 'h' + ' ' + minutes + 'm'
  }

  isOpen &&
    console.log({
      selectedDepartureFlight,
      selectedReturnFlight,
      selectedFlight,
      type,
      selectedFlightBaggage,
    })

  let departureDepartureCity = ''
  let departureDepartureAirport = ''
  let departureDepartureDate = ''
  let departureDepartureTime = ''
  let departureArrivalCity = ''
  let departureArrivalAirport = ''
  let departureArrivalDate = ''
  let departureArrivalTime = ''
  let departureLogoUrl = ''
  let departureAirlineName = ''
  let departureFlightCode = ''
  let departureJourneyDuration = 0
  let departureNumOfTransit = ''
  let departureBaggageOption = []
  let departureCurrency = ''
  let departureAmount = 0
  let departureRefundableStatus = 0

  let returnDepartureCity = ''
  let returnDepartureAirport = ''
  let returnDepartureDate = ''
  let returnDepartureTime = ''
  let returnArrivalCity = ''
  let returnArrivalAirport = ''
  let returnArrivalDate = ''
  let returnArrivalTime = ''
  let returnLogoUrl = ''
  let returnAirlineName = ''
  let returnFlightCode = ''
  let returnJourneyDuration = 0
  let returnNumOfTransit = ''
  let returnBaggageOption = []
  let returnCurrency = ''
  let returnAmount = 0
  let returnRefundableStatus = 0

  let selectedDepartureCity = ''
  let selectedDepartureAirport = ''
  let selectedDepartureDate = ''
  let selectedDepartureTime = ''
  let selectedArrivalCity = ''
  let selectedArrivalAirport = ''
  let selectedArrivalDate = ''
  let selectedArrivalTime = ''
  let selectedLogoUrl = ''
  let selectedAirlineName = ''
  let selectedFlightCode = ''
  let selectedJourneyDuration = 0
  let selectedNumOfTransit = ''
  let selectedBaggageOption = []
  let selectedCurrency = ''
  let selectedAmount = 0
  let selectedRefundableStatus = ''

  if (type === 'one_way') {
    selectedDepartureCity = selectedFlight?.departureAirportDetail?.city
    selectedDepartureAirport = selectedFlight?.departureAirport
    selectedDepartureDate =
      selectedFlight?.journeys[0]?.departureDetail?.departureDate
    selectedDepartureTime =
      selectedFlight?.journeys[0]?.departureDetail?.departureTime
    selectedArrivalCity = selectedFlight?.arrivalAirportDetail?.city
    selectedArrivalAirport = selectedFlight?.arrivalAirport
    selectedArrivalDate =
      selectedFlight?.journeys[0]?.departureDetail?.arrivalDate
    selectedArrivalTime =
      selectedFlight?.journeys[0]?.arrivalDetail?.arrivalTime
    selectedLogoUrl = selectedFlight?.airlineDetail[0]?.logoUrl
    selectedAirlineName = selectedFlight?.airlineDetail[0]?.airlineName
    selectedFlightCode = selectedFlight?.journeys[0]?.segments[0]?.flightCode
    selectedJourneyDuration = selectedFlight?.journeys[0]?.journeyDuration
    selectedNumOfTransit = selectedFlight?.numOfTransits
    selectedBaggageOption = selectedFlight?.journeys[0]?.segments
    selectedCurrency =
      selectedFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.currency
    selectedAmount =
      selectedFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount
    selectedRefundableStatus =
      selectedReturnFlight?.journeys[0]?.refundableStatus
  } else if (type === 'round_trip') {
    departureDepartureCity =
      selectedDepartureFlight?.departureAirportDetail?.city
    departureDepartureAirport = selectedDepartureFlight?.departureAirport
    departureDepartureDate =
      selectedDepartureFlight?.journeys[0]?.departureDetail?.departureDate
    departureDepartureTime =
      selectedDepartureFlight?.journeys[0]?.departureDetail?.departureTime
    departureArrivalCity = selectedDepartureFlight?.arrivalAirportDetail?.city
    departureArrivalAirport = selectedDepartureFlight?.arrivalAirport
    departureArrivalDate =
      selectedDepartureFlight?.journeys[0]?.departureDetail?.arrivalDate
    departureArrivalTime =
      selectedDepartureFlight?.journeys[0]?.arrivalDetail?.arrivalTime
    departureLogoUrl = selectedDepartureFlight?.airlineDetail[0]?.logoUrl
    departureAirlineName =
      selectedDepartureFlight?.airlineDetail[0]?.airlineName
    departureFlightCode =
      selectedDepartureFlight?.journeys[0]?.segments[0]?.flightCode
    departureJourneyDuration =
      selectedDepartureFlight?.journeys[0]?.journeyDuration
    departureNumOfTransit = selectedDepartureFlight?.numOfTransits
    departureBaggageOption = selectedDepartureFlight?.journeys[0]?.segments
    departureCurrency =
      selectedDepartureFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare
        ?.currency
    departureAmount =
      selectedDepartureFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare
        ?.amount
    departureRefundableStatus =
      selectedDepartureFlight?.journeys[0]?.refundableStatus

    returnDepartureCity = selectedReturnFlight?.departureAirportDetail?.city
    returnDepartureAirport = selectedReturnFlight?.departureAirport
    returnDepartureDate =
      selectedReturnFlight?.journeys[0]?.departureDetail?.departureDate
    returnDepartureTime =
      selectedReturnFlight?.journeys[0]?.departureDetail?.departureTime
    returnArrivalCity = selectedReturnFlight?.arrivalAirportDetail?.city
    returnArrivalAirport = selectedReturnFlight?.arrivalAirport
    returnArrivalDate =
      selectedReturnFlight?.journeys[0]?.departureDetail?.arrivalDate
    returnArrivalTime =
      selectedReturnFlight?.journeys[0]?.arrivalDetail?.arrivalTime
    returnLogoUrl = selectedReturnFlight?.airlineDetail[0]?.logoUrl
    returnAirlineName = selectedReturnFlight?.airlineDetail[0]?.airlineName
    returnFlightCode =
      selectedReturnFlight?.journeys[0]?.segments[0]?.flightCode
    returnJourneyDuration = selectedReturnFlight?.journeys[0]?.journeyDuration
    returnNumOfTransit = selectedReturnFlight?.numOfTransits
    returnBaggageOption = selectedReturnFlight?.journeys[0]?.segments
    returnCurrency =
      selectedReturnFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare
        ?.currency
    returnAmount =
      selectedReturnFlight?.journeys[0]?.fareInfo?.netToAgent?.adultFare?.amount
    returnRefundableStatus = selectedReturnFlight?.journeys[0]?.refundableStatus
  } else if (type === 'smart_combo') {
    departureDepartureCity =
      selectedDepartureFlight?.departureAirportDetail?.city
    departureDepartureAirport =
      selectedDepartureFlight?.departureDetail?.airportCode
    departureDepartureDate =
      selectedDepartureFlight?.segments[0]?.departureDetail?.departureDate
    departureDepartureTime =
      selectedDepartureFlight?.segments[0]?.departureDetail?.departureTime
    departureArrivalCity = selectedDepartureFlight?.arrivalAirportDetail?.city
    departureArrivalAirport =
      selectedDepartureFlight?.arrivalDetail?.airportCode
    departureArrivalDate =
      selectedDepartureFlight?.segments[0]?.departureDetail?.arrivalDate
    departureArrivalTime =
      selectedDepartureFlight?.segments[0]?.arrivalDetail?.arrivalTime
    departureLogoUrl = selectedDepartureFlight?.airlineDetail[0]?.logoUrl
    departureAirlineName =
      selectedDepartureFlight?.airlineDetail[0]?.airlineName
    departureFlightCode = selectedDepartureFlight?.segments[0]?.flightCode
    departureJourneyDuration = selectedDepartureFlight?.journeyDuration
    departureNumOfTransit = selectedDepartureFlight?.numOfTransits
    departureBaggageOption = selectedDepartureFlight?.segments
    departureCurrency = selectedDepartureFlight?.fareCurrency
    departureAmount = selectedDepartureFlight?.fare
    departureRefundableStatus =
      selectedDepartureFlight?.segments[0]?.refundableStatus

    returnDepartureCity = selectedReturnFlight?.departureAirportDetail?.city
    returnDepartureAirport = selectedReturnFlight?.departureDetail?.airportCode
    returnDepartureDate =
      selectedReturnFlight?.segments[0]?.departureDetail?.departureDate
    returnDepartureTime =
      selectedReturnFlight?.segments[0]?.departureDetail?.departureTime
    returnArrivalCity = selectedReturnFlight?.arrivalAirportDetail?.city
    returnArrivalAirport = selectedReturnFlight?.arrivalDetail?.airportCode
    returnArrivalDate =
      selectedReturnFlight?.segments[0]?.departureDetail?.arrivalDate
    returnArrivalTime =
      selectedReturnFlight?.segments[0]?.arrivalDetail?.arrivalTime
    returnLogoUrl = selectedReturnFlight?.airlineDetail[0]?.logoUrl
    returnAirlineName = selectedReturnFlight?.airlineDetail[0]?.airlineName
    returnFlightCode = selectedReturnFlight?.segments[0]?.flightCode
    returnJourneyDuration = selectedReturnFlight?.journeyDuration
    returnNumOfTransit = selectedReturnFlight?.numOfTransits
    returnBaggageOption = selectedReturnFlight?.segments
    returnCurrency = selectedReturnFlight?.fareCurrency
    returnAmount = selectedReturnFlight?.fare
    returnRefundableStatus = selectedReturnFlight?.segments[0]?.refundableStatus
  }

  console.log({ selectedDepartureFlightBaggage, selectedReturnFlightBaggage })

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'6xl'}>
      <ModalOverlay />
      <ModalContent margin="10px">
        <ModalHeader
          as={Flex}
          direction={isLargerThan500 ? 'row' : 'column'}
          alignItems={'center'}
          gap={'10px'}
          borderBottomWidth={'2px'}
          borderBottomColor={'gray'}
          marginX={'20px'}
          paddingX={'0px'}
        >
          <Title
            type={type}
            departureDepartureAirport={departureDepartureAirport}
            departureDepartureCity={departureDepartureCity}
            selectedDepartureCity={selectedDepartureCity}
            selectedDepartureAirport={selectedDepartureAirport}
            departureArrivalCity={departureArrivalCity}
            selectedArrivalAirport={selectedArrivalAirport}
            selectedArrivalCity={selectedArrivalCity}
          />
        </ModalHeader>
        <ModalCloseButton top={'15px'} />
        <ModalBody as={Flex} flexDirection={'column'} gap={'10px'}>
          <Flex gap={'10px'} direction={isLargerThan500 ? 'row' : 'column'}>
            <Flex
              flexDirection={'column'}
              width={isLargerThan500 ? '150%' : '100%'}
              gap={'10px'}
            >
              {type === 'round_trip' || type === 'smart_combo' ? (
                <>
                  <Flex
                    flexDirection={'column'}
                    padding={'20px'}
                    rounded={'base'}
                    boxShadow={'0px 0px 10px 1px #e8e8e8'}
                  >
                    <Text
                      fontWeight={'semibold'}
                      marginBottom={'10px'}
                      fontSize={'lg'}
                    >
                      {departureDepartureDate}
                    </Text>
                    <Flex flexDirection={'column'} gap={'10px'}>
                      <Flex alignItems={'center'} gap={'10px'}>
                        <Image
                          height={'40px'}
                          src={departureLogoUrl}
                          objectFit={'cover'}
                        />
                        <Flex flexDirection={'column'}>
                          <Text fontWeight={'semibold'}>
                            {departureAirlineName} ({departureFlightCode})
                          </Text>
                          {/* <Text fontWeight={'thin'}>{oosData?.seatClass}</Text> */}
                        </Flex>
                      </Flex>
                      <Flex
                        direction={isLargerThan500 ? 'row' : 'column'}
                        gap={'20px'}
                        justifyContent={'space-between'}
                        marginTop={'10px'}
                      >
                        <Flex flexDirection={'column'}>
                          <Text fontWeight={'semibold'}>
                            {departureDepartureTime}
                          </Text>
                          <Text fontWeight={'thin'}>
                            {departureDepartureCity} (
                            {departureDepartureAirport})
                          </Text>
                        </Flex>
                        <Flex flexDirection={'column'}>
                          <Text fontWeight={'semibold'}>
                            {departureArrivalTime}
                          </Text>
                          <Text fontWeight={'thin'}>
                            {departureArrivalCity} ({departureArrivalAirport})
                          </Text>
                        </Flex>
                        <Flex flexDirection={'column'}>
                          <Text fontWeight={'semibold'}>
                            {handleNumberToHoursAndMinutes(
                              departureJourneyDuration
                            )}
                          </Text>
                          <Text fontWeight={'thin'}>
                            {departureNumOfTransit === '0'
                              ? 'Direct'
                              : 'Transit'}
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                    {departureBaggageOption?.map((segment, segmentIndex) => {
                      return (
                        <Flex
                          direction="column"
                          marginTop="20px"
                          key={segmentIndex}
                        >
                          <Text>
                            {segment?.departureDetail?.airportCode} -{' '}
                            {segment?.arrivalDetail?.airportCode}
                          </Text>
                          <Grid
                            templateColumns={
                              isLargerThan500
                                ? 'repeat(4, 1fr)'
                                : 'repeat(2, 1fr)'
                            }
                            gap={'10px'}
                          >
                            {segment?.addOns?.baggageOptions
                              ?.filter(
                                (value, index, self) =>
                                  index ===
                                  self.findIndex((t) => t.id === value.id)
                              )
                              ?.map((b, i) => {
                                const departure =
                                  segment?.departureDetail?.airportCode
                                const arrival =
                                  segment?.arrivalDetail?.airportCode
                                const route = `${departure}-${arrival}`
                                const isExist =
                                  selectedDepartureFlightBaggage?.find(
                                    (x) => x?.route == route && x?.id == b?.id
                                  ) !== undefined
                                return (
                                  <GridItem
                                    key={i}
                                    boxShadow={'0px 0px 10px 1px #e8e8e8'}
                                    padding={'20px'}
                                    rounded={'base'}
                                    textAlign={'center'}
                                    onClick={() => {
                                      let tempSelectedDepartureFlightBaggage = [
                                        ...selectedDepartureFlightBaggage,
                                      ]
                                      let targetIndex =
                                        tempSelectedDepartureFlightBaggage?.findIndex(
                                          (x) => x?.route == route
                                        )
                                      if (targetIndex >= 0) {
                                        tempSelectedDepartureFlightBaggage[
                                          targetIndex
                                        ] = {
                                          ...b,
                                          route,
                                        }
                                        setSelectedDepartureFlightBaggage(
                                          (prev) => {
                                            return [
                                              ...tempSelectedDepartureFlightBaggage,
                                            ]
                                          }
                                        )
                                      } else {
                                        setSelectedDepartureFlightBaggage(
                                          (prev) => {
                                            return [
                                              ...prev,
                                              {
                                                ...b,
                                                route,
                                              },
                                            ]
                                          }
                                        )
                                      }
                                    }}
                                    cursor={'pointer'}
                                    backgroundColor={
                                      isExist ? 'blue.500' : 'unset'
                                    }
                                    color={isExist ? 'white' : 'unset'}
                                  >
                                    <Text fontWeight={'semibold'}>
                                      {b?.baggageWeight} {b?.baggageType}
                                    </Text>
                                    <Text>
                                      {b?.netToAgent?.currency}{' '}
                                      {b?.netToAgent?.amount}
                                    </Text>
                                  </GridItem>
                                )
                              })}
                          </Grid>
                        </Flex>
                      )
                    })}
                  </Flex>
                  <Flex
                    flexDirection={'column'}
                    padding={'20px'}
                    rounded={'base'}
                    boxShadow={'0px 0px 10px 1px #e8e8e8'}
                  >
                    <Text
                      fontWeight={'semibold'}
                      marginBottom={'10px'}
                      fontSize={'lg'}
                    >
                      {returnDepartureDate}
                    </Text>
                    <Flex flexDirection={'column'} gap={'10px'}>
                      <Flex alignItems={'center'} gap={'10px'}>
                        <Image
                          height={'40px'}
                          src={returnLogoUrl}
                          objectFit={'cover'}
                        />
                        <Flex flexDirection={'column'}>
                          <Text fontWeight={'semibold'}>
                            {returnAirlineName} ({returnFlightCode})
                          </Text>
                          {/* <Text fontWeight={'thin'}>{oosData?.seatClass}</Text> */}
                        </Flex>
                      </Flex>
                      <Flex
                        gap={'20px'}
                        justifyContent={'space-between'}
                        marginTop={'10px'}
                        direction={isLargerThan500 ? 'row' : 'column'}
                      >
                        <Flex flexDirection={'column'}>
                          <Text fontWeight={'semibold'}>
                            {returnDepartureTime}
                          </Text>
                          <Text fontWeight={'thin'}>
                            {returnDepartureCity} ({returnDepartureAirport})
                          </Text>
                        </Flex>
                        <Flex flexDirection={'column'}>
                          <Text fontWeight={'semibold'}>
                            {returnArrivalTime}
                          </Text>
                          <Text fontWeight={'thin'}>
                            {returnArrivalCity} ({returnArrivalAirport})
                          </Text>
                        </Flex>
                        <Flex flexDirection={'column'}>
                          <Text fontWeight={'semibold'}>
                            {handleNumberToHoursAndMinutes(
                              returnJourneyDuration
                            )}
                          </Text>
                          <Text fontWeight={'thin'}>
                            {returnNumOfTransit === '0' ? 'direct' : 'transit'}
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                    {returnBaggageOption?.map((segment, segmentIndex) => {
                      return (
                        <Flex
                          direction="column"
                          marginTop="20px"
                          key={segmentIndex}
                        >
                          <Text>
                            {segment?.departureDetail?.airportCode} -{' '}
                            {segment?.arrivalDetail?.airportCode}
                          </Text>
                          <Grid
                            templateColumns={
                              isLargerThan500
                                ? 'repeat(4, 1fr)'
                                : 'repeat(2, 1fr)'
                            }
                            gap={'10px'}
                          >
                            {segment?.addOns?.baggageOptions
                              ?.filter(
                                (value, index, self) =>
                                  index ===
                                  self.findIndex((t) => t.id === value.id)
                              )
                              ?.map((b, i) => {
                                const departure =
                                  segment?.departureDetail?.airportCode
                                const arrival =
                                  segment?.arrivalDetail?.airportCode
                                const route = `${departure}-${arrival}`
                                const isExist =
                                  selectedReturnFlightBaggage?.find(
                                    (x) => x?.route == route && x?.id == b?.id
                                  ) !== undefined
                                return (
                                  <GridItem
                                    key={i}
                                    boxShadow={'0px 0px 10px 1px #e8e8e8'}
                                    padding={'20px'}
                                    rounded={'base'}
                                    textAlign={'center'}
                                    onClick={() => {
                                      let tempSelectedReturnFlightBaggage = [
                                        ...selectedReturnFlightBaggage,
                                      ]
                                      let targetIndex =
                                        tempSelectedReturnFlightBaggage?.findIndex(
                                          (x) => x?.route == route
                                        )
                                      if (targetIndex >= 0) {
                                        tempSelectedReturnFlightBaggage[
                                          targetIndex
                                        ] = {
                                          ...b,
                                          route,
                                        }
                                        setSelectedReturnFlightBaggage(
                                          (prev) => {
                                            return [
                                              ...tempSelectedReturnFlightBaggage,
                                            ]
                                          }
                                        )
                                      } else {
                                        setSelectedReturnFlightBaggage(
                                          (prev) => {
                                            return [
                                              ...prev,
                                              {
                                                ...b,
                                                route,
                                              },
                                            ]
                                          }
                                        )
                                      }
                                    }}
                                    cursor={'pointer'}
                                    backgroundColor={
                                      isExist ? 'blue.500' : 'unset'
                                    }
                                    color={isExist ? 'white' : 'unset'}
                                  >
                                    <Text fontWeight={'semibold'}>
                                      {b?.baggageWeight} {b?.baggageType}
                                    </Text>
                                    <Text>
                                      {b?.netToAgent?.currency}{' '}
                                      {b?.netToAgent?.amount}
                                    </Text>
                                  </GridItem>
                                )
                              })}
                          </Grid>
                        </Flex>
                      )
                    })}
                  </Flex>
                </>
              ) : (
                <Flex
                  flexDirection={'column'}
                  padding={'20px'}
                  rounded={'base'}
                  boxShadow={'0px 0px 10px 1px #e8e8e8'}
                >
                  <Text
                    fontWeight={'semibold'}
                    marginBottom={'10px'}
                    fontSize={'lg'}
                  >
                    {selectedDepartureDate}
                  </Text>
                  <Flex flexDirection={'column'} gap={'10px'}>
                    <Flex alignItems={'center'} gap={'10px'}>
                      <Image
                        height={'40px'}
                        src={selectedLogoUrl}
                        objectFit={'cover'}
                      />
                      <Flex flexDirection={'column'}>
                        <Text fontWeight={'semibold'}>
                          {selectedAirlineName} ({selectedFlightCode})
                        </Text>
                        {/* <Text fontWeight={'thin'}>{oosData?.seatClass}</Text> */}
                      </Flex>
                    </Flex>
                    <Flex
                      gap={'20px'}
                      justifyContent={'space-between'}
                      marginTop={'10px'}
                      direction={isLargerThan500 ? 'row' : 'column'}
                    >
                      <Flex flexDirection={'column'}>
                        <Text fontWeight={'semibold'}>
                          {selectedDepartureTime}
                        </Text>
                        <Text fontWeight={'thin'}>
                          {selectedDepartureCity} ({selectedDepartureAirport})
                        </Text>
                      </Flex>
                      <Flex flexDirection={'column'}>
                        <Text fontWeight={'semibold'}>
                          {selectedArrivalTime}
                        </Text>
                        <Text fontWeight={'thin'}>
                          {selectedArrivalCity} ({selectedArrivalAirport})
                        </Text>
                      </Flex>
                      <Flex flexDirection={'column'}>
                        <Text fontWeight={'semibold'}>
                          {handleNumberToHoursAndMinutes(
                            selectedJourneyDuration
                          )}
                        </Text>
                        <Text fontWeight={'thin'}>
                          {selectedNumOfTransit === '0' ? 'direct' : 'transit'}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  {selectedBaggageOption?.map((segment, segmentIndex) => {
                    return (
                      <Flex
                        direction="column"
                        marginTop="20px"
                        key={segmentIndex}
                      >
                        <Text>
                          {segment?.departureDetail?.airportCode} -{' '}
                          {segment?.arrivalDetail?.airportCode}
                        </Text>
                        <Grid
                          templateColumns={
                            isLargerThan500
                              ? 'repeat(4, 1fr)'
                              : 'repeat(2, 1fr)'
                          }
                          gap={'10px'}
                        >
                          {segment?.addOns?.baggageOptions
                            ?.filter(
                              (value, index, self) =>
                                index ===
                                self.findIndex((t) => t.id === value.id)
                            )
                            ?.map((b, i) => {
                              const departure =
                                segment?.departureDetail?.airportCode
                              const arrival =
                                segment?.arrivalDetail?.airportCode
                              const route = `${departure}-${arrival}`
                              const isExist =
                                selectedFlightBaggage?.find(
                                  (x) => x?.route == route && x?.id == b?.id
                                ) !== undefined
                              return (
                                <GridItem
                                  key={i}
                                  boxShadow={'0px 0px 10px 1px #e8e8e8'}
                                  padding={'20px'}
                                  rounded={'base'}
                                  textAlign={'center'}
                                  onClick={() => {
                                    let tempSelectedFlightBaggage = [
                                      ...selectedFlightBaggage,
                                    ]
                                    let targetIndex =
                                      tempSelectedFlightBaggage?.findIndex(
                                        (x) => x?.route == route
                                      )
                                    if (targetIndex >= 0) {
                                      tempSelectedFlightBaggage[targetIndex] = {
                                        ...b,
                                        route,
                                      }
                                      setSelectedFlightBaggage((prev) => {
                                        return [...tempSelectedFlightBaggage]
                                      })
                                    } else {
                                      setSelectedFlightBaggage((prev) => {
                                        return [
                                          ...prev,
                                          {
                                            ...b,
                                            route,
                                          },
                                        ]
                                      })
                                    }
                                  }}
                                  cursor={'pointer'}
                                  backgroundColor={
                                    isExist ? 'blue.500' : 'unset'
                                  }
                                  color={isExist ? 'white' : 'unset'}
                                >
                                  <Text fontWeight={'semibold'}>
                                    {b?.baggageWeight} {b?.baggageType}
                                  </Text>
                                  <Text>
                                    {b?.netToAgent?.currency}{' '}
                                    {Number(
                                      b?.netToAgent?.amount
                                    ).toLocaleString('id')}
                                  </Text>
                                </GridItem>
                              )
                            })}
                        </Grid>
                      </Flex>
                    )
                  })}
                </Flex>
              )}
            </Flex>

            <Flex flexDirection={'column'} width={'100%'}>
              <Text fontWeight={'semibold'}>Additional Information</Text>
              {type === 'round_trip' || type === 'smart_combo' ? (
                <>
                  <Flex flexDirection={'column'} marginTop={'20px'}>
                    <Text>
                      {departureDepartureAirport} - {departureArrivalAirport}
                    </Text>
                    <Text
                      color={
                        departureRefundableStatus === 'REFUNDABLE'
                          ? 'green.300'
                          : 'red.300'
                      }
                    >
                      {departureRefundableStatus === 'REFUNDABLE'
                        ? 'Refundable'
                        : 'Not Refundable'}
                    </Text>
                  </Flex>
                  <Flex flexDirection={'column'} marginTop={'20px'}>
                    <Text>
                      {returnDepartureAirport} - {returnArrivalAirport}
                    </Text>
                    <Text
                      color={
                        returnRefundableStatus === 'REFUNDABLE'
                          ? 'green.300'
                          : 'red.300'
                      }
                    >
                      {returnRefundableStatus === 'REFUNDABLE'
                        ? 'Refundable'
                        : 'Not Refundable'}
                    </Text>
                  </Flex>
                </>
              ) : (
                <Flex flexDirection={'column'} marginTop={'20px'}>
                  <Text>
                    {selectedDepartureAirport} - {selectedArrivalAirport}
                  </Text>
                  <Text
                    color={
                      selectedRefundableStatus === 'REFUNDABLE'
                        ? 'green.300'
                        : 'red.300'
                    }
                  >
                    {selectedRefundableStatus === 'REFUNDABLE'
                      ? 'Refundable'
                      : 'Not Refundable'}
                  </Text>
                </Flex>
              )}
              <Text fontWeight={'semibold'} marginTop={'20px'}>
                Price Detail
              </Text>
              <Flex justifyContent={'space-between'}>
                <Text>
                  {type === 'round_trip' || type === 'smart_combo'
                    ? `${departureAirlineName} ${tickets?.data?.data?.trip?.passenger.length}x`
                    : `${selectedAirlineName} ${tickets?.data?.data?.trip?.passenger.length}x`}
                </Text>
                <Text>
                  {type === 'round_trip' || type === 'smart_combo'
                    ? departureCurrency
                    : selectedCurrency}
                  .{' '}
                  {type === 'round_trip' || type == 'smart_combo'
                    ? (
                        Number(departureAmount) *
                        tickets?.data?.data?.trip?.passenger.length
                      ).toLocaleString()
                    : (
                        Number(selectedAmount) *
                        tickets?.data?.data?.trip?.passenger.length
                      ).toLocaleString()}
                </Text>
              </Flex>
              {type === 'round_trip' ||
                (type === 'smart_combo' && (
                  <Flex justifyContent={'space-between'}>
                    <Text>
                      {returnAirlineName}{' '}
                      {tickets?.data?.data?.trip?.passenger.length}x
                    </Text>
                    <Text>
                      {returnCurrency}.{' '}
                      {(
                        Number(returnAmount) *
                        tickets?.data?.data?.trip?.passenger.length
                      ).toLocaleString()}
                    </Text>
                  </Flex>
                ))}
              <Text marginTop={'20px'}>Baggage</Text>
              {type === 'round_trip' || type === 'smart_combo' ? (
                <>
                  {selectedDepartureFlightBaggage?.map((b, i) => {
                    return (
                      <Flex justifyContent={'space-between'}>
                        <Text>
                          {b?.route} {b?.baggageWeight} {b?.baggageType}{' '}
                          {tickets?.data?.data?.trip?.passenger.length}x
                        </Text>
                        <Text>
                          {b?.netToAgent?.currency} {b && b?.netToAgent?.amount}
                        </Text>
                      </Flex>
                    )
                  })}
                  {selectedReturnFlightBaggage?.map((b, i) => {
                    return (
                      <Flex justifyContent={'space-between'}>
                        <Text>
                          {b?.route} {b?.baggageWeight} {b?.baggageType}{' '}
                          {tickets?.data?.data?.trip?.passenger.length}x
                        </Text>
                        <Text>
                          {b?.netToAgent?.currency} {b && b?.netToAgent?.amount}
                        </Text>
                      </Flex>
                    )
                  })}
                </>
              ) : null}
              {selectedFlightBaggage && type === 'one_way' && (
                <Flex direction="column">
                  {selectedFlightBaggage?.map((b, i) => {
                    return (
                      <Flex justifyContent={'space-between'}>
                        <Text>
                          {b?.route} {b?.baggageWeight} {b?.baggageType}{' '}
                          {tickets?.data?.data?.trip?.passenger.length}x
                        </Text>
                        <Text>
                          {b?.netToAgent?.currency} {b && b?.netToAgent?.amount}
                        </Text>
                      </Flex>
                    )
                  })}
                </Flex>
              )}
              <Flex justifyContent={'space-between'} marginTop={'20px'}>
                <Text fontWeight={'semibold'}>Price you pay</Text>
                <Text>
                  {type === 'round_trip' || type === 'smart_combo'
                    ? departureCurrency
                    : selectedCurrency}
                  .{' '}
                  {type === 'round_trip' || type === 'smart_combo'
                    ? (
                        Number(departureAmount) *
                          tickets?.data?.data?.trip?.passenger.length +
                        Number(
                          selectedDepartureFlightBaggage &&
                            selectedDepartureFlightBaggage.reduce(
                              (accumulator, current) =>
                                accumulator +
                                parseFloat(current.netToAgent?.amount),
                              0
                            )
                        ) *
                          tickets?.data?.data?.trip?.passenger.length +
                        Number(returnAmount) *
                          tickets?.data?.data?.trip?.passenger.length +
                        Number(
                          selectedReturnFlightBaggage &&
                            selectedReturnFlightBaggage.reduce(
                              (accumulator, current) =>
                                accumulator +
                                parseFloat(current.netToAgent?.amount),
                              0
                            )
                        ) *
                          tickets?.data?.data?.trip?.passenger.length
                      ).toLocaleString()
                    : (
                        Number(selectedAmount) *
                          tickets?.data?.data?.trip?.passenger.length +
                        Number(
                          selectedFlightBaggage &&
                            selectedFlightBaggage.reduce(
                              (accumulator, current) =>
                                accumulator +
                                parseFloat(current.netToAgent?.amount),
                              0
                            )
                        ) *
                          tickets?.data?.data?.trip?.passenger.length
                      ).toLocaleString()}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Back to Ticket
          </Button>
          <Button
            colorScheme="blue"
            ml={'20px'}
            onClick={() => {
              onClose()
              onContinue()
            }}
          >
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalBaggage
