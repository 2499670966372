import React from 'react'
import { Alert, AlertIcon, AlertTitle } from '@chakra-ui/react'

const Success = ({ isSuccess }) => {
    return (
        <>
            {isSuccess && (
                <Alert status="success">
                    <AlertIcon />
                    <AlertTitle>
                        Success Order, Please back to your Travel Request
                    </AlertTitle>
                </Alert>
            )}
        </>
    )
}

export default Success
