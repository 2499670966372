import React from 'react'
import TripTitleSkeleton from './TripTitleSkeleton'
import { format } from 'date-fns'
import { Flex, Stack, Text, VStack } from '@chakra-ui/react'

const TripTitle = ({ tickets, isSuccess }) => {
    return (
        <Flex flexDirection="column" textAlign="center">
            {tickets?.isLoading
                ? !isSuccess && <TripTitleSkeleton />
                : !isSuccess && (
                      <VStack>
                          <Text fontWeight="semibold">
                              {tickets?.data?.data?.trip?.ticket}
                              {tickets?.data?.bookingFlightType === 'round_trip'
                                  ? ' (Round Trip)'
                                  : tickets?.data?.bookingFlightType ===
                                    'one_way'
                                  ? ' (One Way)'
                                  : ' (Smart Combo) '}
                          </Text>
                          {tickets?.data?.bookingFlightType === 'round_trip' ||
                          tickets?.data?.bookingFlightType === 'smart_combo' ||
                          tickets?.data?.bookingFlightType === 'one_way' ? (
                              <Stack>
                                  <Text fontWeight="semibold">
                                      {
                                          tickets?.data?.data?.trip
                                              ?.flight_merge_from?.name
                                      }{' '}
                                      -{' '}
                                      {
                                          tickets?.data?.data?.trip
                                              ?.flight_merge_to?.name
                                      }
                                  </Text>
                                  <Text fontWeight="semibold">
                                      {format(
                                          new Date(
                                              tickets?.data?.data?.trip?.departure_date
                                          ),
                                          'iiii, dd MMMM yyyy'
                                      )}
                                  </Text>
                              </Stack>
                          ) : null}
                          {tickets?.data?.isBookHotel ? (
                              <Stack>
                                  <Text fontWeight="semibold">
                                      {
                                          tickets?.data?.data?.trip
                                              ?.hotel_lokasi?.name
                                      }
                                  </Text>
                                  <Text fontWeight="semibold">
                                      {format(
                                          new Date(
                                              tickets?.data?.data?.trip?.hotel_check_in
                                          ),
                                          'iiii, dd MMMM yyyy'
                                      )}{' '}
                                      -{' '}
                                      {format(
                                          new Date(
                                              tickets?.data?.data?.trip?.hotel_check_out
                                          ),
                                          'iiii, dd MMMM yyyy'
                                      )}
                                  </Text>
                              </Stack>
                          ) : null}
                      </VStack>
                  )}
        </Flex>
    )
}

export default TripTitle
