import React from 'react'
import FlightItem from './FlightItem'

const OneWay = ({
    isShowFlightTickets,
    tickets,
    search,
    setSearch,
    setSelectedFlight,
}) => {
    const bookingFlightType = tickets?.data?.bookingFlightType

    if (!isShowFlightTickets) return
    if (bookingFlightType !== 'one_way') return

    return (
        <>
            {tickets?.data?.data?.flights?.map((flight, index) => {
                if (!search) {
                    return (
                        <FlightItem
                            key={index}
                            data={flight}
                            onClick={() => {
                                setSelectedFlight(flight)
                                setSearch('')
                            }}
                        />
                    )
                }
                if (
                    search &&
                    flight?.airlineDetail[0]?.airlineName
                        .toLowerCase()
                        .includes(search.toLowerCase())
                ) {
                    return (
                        <FlightItem
                            key={index}
                            data={flight}
                            onClick={() => {
                                setSelectedFlight(flight)
                                setSearch('')
                            }}
                        />
                    )
                }
                return null
            })}
        </>
    )
}

export default OneWay
