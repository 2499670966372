import React from 'react'
import { Button, useMediaQuery } from '@chakra-ui/react'

const ButtonBack = () => {
    const [isLargerThan768] = useMediaQuery('(min-width: 768px)')

    return (
        <>
            {isLargerThan768 && (
                <Button
                    width="max-content"
                    fontSize="sm"
                    onClick={() => {
                        const urlBack =
                            window?.location?.search?.split('url_back=')[1]
                        // window.parent.location.href = searchParams?.get('url_back')
                        // console.log(encodeURIComponent(searchParams?.get('url_back')))
                        window.parent.location.href = urlBack
                    }}
                >
                    Back
                </Button>
            )}
        </>
    )
}

export default ButtonBack
