export const extractAddOnOptions = (resp) => {
  let baggageOptions = []
  let mealOptions = []

  const journey = resp?.data?.journeys[0]

  if (
    // Checking baggageOptions outside segments
    journey?.addOnOptions?.baggageOptions?.length > 0
  ) {
    // Appending baggageOptions with type "journey"
    baggageOptions = journey?.addOnOptions?.baggageOptions.map((option) => ({
      ...option,
      type: 'journey',
    }))
  }

  if (
    // Checking mealOptions outside segments
    journey?.addOnOptions?.mealOptions?.length > 0
  ) {
    // Appending mealOptions with type "journey"
    mealOptions = journey?.addOnOptions?.mealOptions.map((option) => ({
      ...option,
      type: 'journey',
    }))
  }

  if (
    // Checking baggageOptions inside segments
    journey?.segments[0]?.addOnOptions?.baggageOptions?.length > 0
  ) {
    // Appending baggageOptions with type "segment"
    baggageOptions = journey?.segments[0]?.addOnOptions?.baggageOptions.map(
      (option) => ({ ...option, type: 'segment' })
    )
  }

  if (
    // Checking mealOptions inside segments
    journey?.segments[0]?.addOnOptions?.mealOptions?.length > 0
  ) {
    // Appending mealOptions with type "segment"
    mealOptions = journey?.segments[0]?.addOnOptions?.mealOptions.map(
      (option) => ({ ...option, type: 'segment' })
    )
  }

  return { baggageOptions, mealOptions }
}

export const extractAddOnOptionsSmartComboDeparture = (resp) => {
  let baggageOptions = []
  let mealOptions = []

  const journey = resp?.data?.journeys[0]

  if (
    // Checking baggageOptions outside segments
    journey?.addOnOptions?.baggageOptions?.length > 0
  ) {
    // Appending baggageOptions with type "journey"
    baggageOptions = journey?.addOnOptions?.baggageOptions.map((option) => ({
      ...option,
      type: 'journey',
    }))
  }

  if (
    // Checking mealOptions outside segments
    journey?.addOnOptions?.mealOptions?.length > 0
  ) {
    // Appending mealOptions with type "journey"
    mealOptions = journey?.addOnOptions?.mealOptions.map((option) => ({
      ...option,
      type: 'journey',
    }))
  }

  if (
    // Checking baggageOptions inside segments
    journey?.segments[0]?.addOnOptions?.baggageOptions?.length > 0
  ) {
    // Appending baggageOptions with type "segment"
    baggageOptions = journey?.segments[0]?.addOnOptions?.baggageOptions.map(
      (option) => ({ ...option, type: 'segment' })
    )
  }

  if (
    // Checking mealOptions inside segments
    journey?.segments[0]?.addOnOptions?.mealOptions?.length > 0
  ) {
    // Appending mealOptions with type "segment"
    mealOptions = journey?.segments[0]?.addOnOptions?.mealOptions.map(
      (option) => ({ ...option, type: 'segment' })
    )
  }

  return { baggageOptions, mealOptions }
}

export const extractAddOnOptionsSmartComboReturn = (resp) => {
  let baggageOptionsReturn = []
  let mealOptionsReturn = []

  const journey = resp?.data?.journeys[1]

  if (
    // Checking baggageOptions outside segments
    journey?.addOnOptions?.baggageOptions?.length > 0
  ) {
    // Appending baggageOptions with type "journey"
    baggageOptionsReturn = journey?.addOnOptions?.baggageOptions.map(
      (option) => ({
        ...option,
        type: 'journey',
      })
    )
  }

  if (
    // Checking mealOptions outside segments
    journey?.addOnOptions?.mealOptions?.length > 0
  ) {
    // Appending mealOptions with type "journey"
    mealOptionsReturn = journey?.addOnOptions?.mealOptions.map((option) => ({
      ...option,
      type: 'journey',
    }))
  }

  if (
    // Checking baggageOptions inside segments
    journey?.segments[1]?.addOnOptions?.baggageOptions?.length > 0
  ) {
    // Appending baggageOptions with type "segment"
    baggageOptionsReturn =
      journey?.segments[1]?.addOnOptions?.baggageOptions.map((option) => ({
        ...option,
        type: 'segment',
      }))
  }

  if (
    // Checking mealOptions inside segments
    journey?.segments[1]?.addOnOptions?.mealOptions?.length > 0
  ) {
    // Appending mealOptions with type "segment"
    mealOptionsReturn = journey?.segments[1]?.addOnOptions?.mealOptions.map(
      (option) => ({ ...option, type: 'segment' })
    )
  }

  return { baggageOptionsReturn, mealOptionsReturn }
}
