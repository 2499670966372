import React from 'react'
import FlightItem from './FlightItem'

const DepartureFlight = ({
    isShowDepartureFlightTickets,
    tickets,
    setSelectedDepartureFlight,
    setSearch,
    search,
}) => {
    const bookingFlightType = tickets?.data?.bookingFlightType

    if (!isShowDepartureFlightTickets) return
    if (
        bookingFlightType !== 'round_trip' &&
        bookingFlightType !== 'smart_combo'
    )
        return

    let data = []

    if (bookingFlightType === 'round_trip') {
        data = tickets?.data?.data?.flightsRoundTrip?.departure
    } else {
        data = tickets?.data?.data?.flightsSmartCombo?.departure
    }

    return (
        <>
            {data?.map((flight, index) => {
                if (!search) {
                    return (
                        <FlightItem
                            key={index}
                            data={flight}
                            onClick={() => {
                                setSelectedDepartureFlight(flight)
                                setSearch('')
                            }}
                        />
                    )
                }
                if (
                    search &&
                    flight?.airlineDetail[0]?.airlineName
                        .toLowerCase()
                        .includes(search.toLowerCase())
                ) {
                    return (
                        <FlightItem
                            key={index}
                            data={flight}
                            onClick={() => {
                                setSelectedDepartureFlight(flight)
                                setSearch('')
                            }}
                        />
                    )
                }
                return null
            })}
        </>
    )
}

export default DepartureFlight
