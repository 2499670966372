import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import React from 'react'
import { FiSearch } from 'react-icons/fi'

const Searchbar = ({ value, onChange, tickets, isSuccess }) => {
    return (
        <>
            {!tickets?.isError && !isSuccess && (
                <InputGroup boxShadow="0px 0px 10px 1px #e8e8e8">
                    <InputLeftElement
                        pointerEvents="none"
                        children={<FiSearch />}
                    />
                    <Input
                        type="search"
                        placeholder="search ..."
                        disabled={tickets?.isLoading}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                    />
                </InputGroup>
            )}
        </>
    )
}

export default Searchbar
