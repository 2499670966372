import React from 'react'
import { TbRepeat, TbRepeatOff } from 'react-icons/tb'
import { Flex, Text, useMediaQuery } from '@chakra-ui/react'
import { MdOutlineFlight } from 'react-icons/md'

const Title = ({
    type,
    departureDepartureAirport,
    departureDepartureCity,
    selectedDepartureCity,
    selectedDepartureAirport,
    departureArrivalCity,
    selectedArrivalAirport,
    selectedArrivalCity,
}) => {
    const [isLargerThan500] = useMediaQuery('(min-width: 500px)')

    return (
        <>
            <Flex alignItems={'center'} gap={'10px'}>
                <Text>Select Baggage</Text>
                <Text>|</Text>
                <MdOutlineFlight fontSize={'24px'} color={'#307dc8'} />
            </Flex>
            <Flex
                direction={isLargerThan500 ? 'row' : 'column'}
                alignItems={'center'}
                gap={'10px'}
            >
                {type === 'round_trip' || type === 'smart_combo' ? (
                    <Text>
                        {departureDepartureCity} ({departureDepartureAirport})
                    </Text>
                ) : (
                    <Text>
                        {selectedDepartureCity} ({selectedDepartureAirport})
                    </Text>
                )}
                {type === 'round_trip' || type === 'smart_combo' ? (
                    <TbRepeat fontSize={'24px'} color={'gray'} />
                ) : (
                    <TbRepeatOff fontSize={'24px'} color={'gray'} />
                )}
                {type === 'round_trip' || type === 'smart_combo' ? (
                    <Text>
                        {departureArrivalCity} ({departureDepartureAirport})
                    </Text>
                ) : (
                    <Text>
                        {selectedArrivalCity} ({selectedArrivalAirport})
                    </Text>
                )}
            </Flex>
        </>
    )
}

export default Title
