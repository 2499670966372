import React from 'react'
import FlightItem from './FlightItem'

const ReturnFlight = ({
    isShowReturnFlightTickets,
    tickets,
    selectedDepartureFlight,
    setSelectedReturnFlight,
    search,
    setSearch,
}) => {
    const bookingFlightType = tickets?.data?.bookingFlightType

    if (!isShowReturnFlightTickets) return
    if (
        bookingFlightType !== 'round_trip' &&
        bookingFlightType !== 'smart_combo'
    )
        return

    let data = []

    if (bookingFlightType === 'round_trip') {
        data = tickets?.data?.data?.flightsRoundTrip?.return
    } else {
        data =
            tickets?.data?.data?.flightsSmartCombo?.return[
                selectedDepartureFlight?.optionId
            ] ?? []
    }

    return (
        <>
            {data?.map((flight, index) => {
                if (!search) {
                    return (
                        <FlightItem
                            key={index}
                            data={flight}
                            onClick={() => {
                                setSelectedReturnFlight(flight)
                                setSearch('')
                            }}
                        />
                    )
                }
                if (
                    search &&
                    flight?.airlineDetail[0]?.airlineName
                        .toLowerCase()
                        .includes(search.toLowerCase())
                ) {
                    return (
                        <FlightItem
                            key={index}
                            data={flight}
                            onClick={() => {
                                setSelectedReturnFlight(flight)
                                setSearch('')
                            }}
                        />
                    )
                }
                return null
            })}
        </>
    )
}

export default ReturnFlight
