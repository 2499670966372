import React from 'react'
import { Skeleton, VStack } from '@chakra-ui/react'

const TripTitleSkeleton = () => {
    return (
        <VStack>
            <Skeleton
                height="25px"
                width="150px"
                borderRadius="6px"
                margin="0px auto"
            />
            <Skeleton
                height="25px"
                width="150px"
                borderRadius="6px"
                margin="0px auto"
            />
            <Skeleton
                height="25px"
                width="150px"
                borderRadius="6px"
                margin="0px auto"
            />
        </VStack>
    )
}

export default TripTitleSkeleton
